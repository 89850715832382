import { InitialGrowPercent, SceneQuality, TreeType } from "../types/types";

export const maxSeedIndex = 65537;

export function getRandomSeed() {
  return Math.floor(Math.random() * maxSeedIndex);
}

export function calculateOverallGrowth(
  tree: any,
  treeType: TreeType,
  sceneType: SceneQuality | null,
  controls: any,
  camera: any,
  growthFactor: number,
  maxGrowthFactor: number,
  emulateCalendarLook: boolean = false
) {
  switch (treeType) {
    case TreeType.Pine:
      return calculatePineGrowth(tree, sceneType, controls, camera, growthFactor, InitialGrowPercent[treeType], maxGrowthFactor);
    default:
      return calculateAshGrowth(tree, sceneType, controls, camera, growthFactor, InitialGrowPercent[treeType], maxGrowthFactor, emulateCalendarLook);
  }
}

export function calculateAshGrowth(
  tree: any,
  sceneType: SceneQuality | null,
  controls: any,
  camera: any,
  growthFactor: number,
  initialGrowPercent = 0,
  maxGrowthFactor: number,
  emulateCalendarLook: boolean = false
) {
  if (growthFactor < 0) {
    throw new Error('Growth factor must be non negative integer');
  }

  if (initialGrowPercent > 0) {
    growthFactor += (maxGrowthFactor * initialGrowPercent) / 100;

    growthFactor =
      growthFactor <= maxGrowthFactor ? growthFactor : maxGrowthFactor;
  }

  tree.options.branch.force.direction.x = 0;
  tree.options.branch.force.direction.y = 1;
  tree.options.branch.force.direction.z = 0;

  tree.options.leaves.tint = getRandomTint();

  // Level 1
  if (growthFactor < maxGrowthFactor / 3) {
    tree.options.branch.levels = 2;

    tree.options.branch.children[0] = Math.ceil(
      linearTransformFloat(growthFactor, 1, maxGrowthFactor / 3, 1, 10)
    );
    tree.options.branch.children[1] = Math.ceil(
      linearTransformFloat(growthFactor, 1, maxGrowthFactor / 3, 1, 3)
    );
    tree.options.branch.children[2] = 2;

    tree.options.branch.force.strength = -0.02;

    tree.options.branch.angle[1] = 48;
    tree.options.branch.angle[2] = 75;
    tree.options.branch.angle[3] = 60;

    tree.options.branch.sections[0] = 12;
    tree.options.branch.sections[1] = 10;
    tree.options.branch.sections[2] = 10;
    tree.options.branch.sections[3] = 10;

    tree.options.branch.segments[0] = 8;
    tree.options.branch.segments[1] = 6;
    tree.options.branch.segments[2] = 4;
    tree.options.branch.segments[3] = 3;

    tree.options.branch.start[1] = 0.53;
    tree.options.branch.start[2] = 0.33;
    tree.options.branch.start[3] = 0;

    tree.options.branch.taper[0] = 0.7;
    tree.options.branch.taper[1] = 0.7;
    tree.options.branch.taper[2] = 0.7;
    tree.options.branch.taper[3] = 0.7;

    tree.options.branch.twist[0] = 0.3;
    tree.options.branch.twist[1] = -0.07;
    tree.options.branch.twist[2] = 0;
    tree.options.branch.twist[3] = 0;

    tree.options.branch.length[0] = linearTransformFloat(
      growthFactor,
      1,
      maxGrowthFactor / 3,
      0,
      15
    );
    tree.options.branch.length[1] = linearTransformFloat(
      growthFactor,
      1,
      maxGrowthFactor / 3,
      0,
      12
    );
    tree.options.branch.length[2] = linearTransformFloat(
      growthFactor,
      0,
      maxGrowthFactor / 3,
      0,
      2.59
    );

    tree.options.branch.radius[0] = linearTransformFloat(
      growthFactor,
      1,
      maxGrowthFactor / 3,
      0,
      0.81
    );
    tree.options.branch.radius[1] = linearTransformFloat(
      growthFactor,
      1,
      maxGrowthFactor / 3,
      0,
      0.56
    );
    tree.options.branch.radius[2] = linearTransformFloat(
      growthFactor,
      1,
      maxGrowthFactor / 3,
      0,
      0.76
    );
    tree.options.branch.radius[3] = linearTransformFloat(
      growthFactor,
      1,
      maxGrowthFactor / 3,
      0,
      0.7
    );

    tree.options.branch.gnarliness[0] = linearTransformFloat(
      growthFactor,
      1,
      maxGrowthFactor / 3,
      0,
      0.1
    );
    tree.options.branch.gnarliness[1] = linearTransformFloat(
      growthFactor,
      1,
      maxGrowthFactor / 3,
      0,
      0.09
    );
    tree.options.branch.gnarliness[2] = linearTransformFloat(
      growthFactor,
      1,
      maxGrowthFactor / 3,
      0,
      0.05
    );
    tree.options.branch.gnarliness[3] = linearTransformFloat(
      growthFactor,
      1,
      maxGrowthFactor / 3,
      0,
      0.09
    );

    tree.options.leaves.angle = 55;
    tree.options.leaves.start = 0;
    tree.options.leaves.sizeVariance = 0.72;
    tree.options.leaves.alphaTest = 0.5

    tree.options.leaves.size = linearTransformFloat(
      growthFactor,
      1,
      maxGrowthFactor / 3,
      0,
      2.05
    );

    tree.options.leaves.count = linearTransformFloat(
      growthFactor,
      1,
      maxGrowthFactor / 3,
      1,
      30
    );

    if (sceneType && controls && camera) {
      if (growthFactor <= maxGrowthFactor / 6) {
        if (sceneType === SceneQuality.Solo) {
          controls.target.set(0, 10, 0);
          camera.position.set(20, 15, 0);
        } else {
          controls.target.set(0, 10, 0);
          camera.position.set(30, 20, 0);
        }
      } else {
        if (sceneType === SceneQuality.Solo) {
          controls.target.set(0, 15, 0);
          camera.position.set(45, 30, 0);
        } else {
          controls.target.set(0, 15, 0);
          camera.position.set(40, 20, 0);
        }
      }
    }
  }

  // Level 2
  if (
    growthFactor >= maxGrowthFactor / 3 &&
    growthFactor <= (maxGrowthFactor / 3) * 2
  ) {
    tree.options.branch.levels = 3;
    tree.options.branch.children[0] = 7;
    tree.options.branch.children[1] = Math.ceil(
      linearTransformFloat(
        growthFactor,
        maxGrowthFactor / 3,
        (maxGrowthFactor / 3) * 2,
        3,
        5
      )
    );
    tree.options.branch.children[2] = 2;

    tree.options.branch.force.strength = -0.06;

    tree.options.branch.angle[1] = 48;
    tree.options.branch.angle[2] = 75;
    tree.options.branch.angle[3] = 60;

    tree.options.branch.sections[0] = 12;
    tree.options.branch.sections[1] = 8;
    tree.options.branch.sections[2] = 6;
    tree.options.branch.sections[3] = 4;

    tree.options.branch.segments[0] = 12;
    tree.options.branch.segments[1] = 6;
    tree.options.branch.segments[2] = 4;
    tree.options.branch.segments[3] = 3;

    tree.options.branch.start[1] = 0.23;
    tree.options.branch.start[2] = 0.33;
    tree.options.branch.start[3] = 0;

    tree.options.branch.taper[0] = 0.7;
    tree.options.branch.taper[1] = 0.7;
    tree.options.branch.taper[2] = 0.7;
    tree.options.branch.taper[3] = 0.7;

    tree.options.branch.twist[0] = 0.09;
    tree.options.branch.twist[1] = -0.07;
    tree.options.branch.twist[2] = 0;
    tree.options.branch.twist[3] = 0;

    tree.options.branch.length[0] = linearTransformFloat(
      growthFactor,
      maxGrowthFactor / 3,
      (maxGrowthFactor / 3) * 2,
      15,
      35
    );
    tree.options.branch.length[1] = linearTransformFloat(
      growthFactor,
      maxGrowthFactor / 3,
      (maxGrowthFactor / 3) * 2,
      12,
      21
    );
    tree.options.branch.length[2] = linearTransformFloat(
      growthFactor,
      maxGrowthFactor / 3,
      (maxGrowthFactor / 3) * 2,
      2.59,
      6.51
    );

    tree.options.branch.radius[0] = linearTransformFloat(
      growthFactor,
      maxGrowthFactor / 3,
      (maxGrowthFactor / 3) * 2,
      0.81,
      2
    );
    tree.options.branch.radius[1] = linearTransformFloat(
      growthFactor,
      maxGrowthFactor / 3,
      (maxGrowthFactor / 3) * 2,
      0.56,
      0.63
    );
    tree.options.branch.radius[2] = linearTransformFloat(
      growthFactor,
      maxGrowthFactor / 3,
      (maxGrowthFactor / 3) * 2,
      0.76,
      0.8
    );
    tree.options.branch.radius[3] = linearTransformFloat(
      growthFactor,
      maxGrowthFactor / 3,
      (maxGrowthFactor / 3) * 2,
      0.7,
      0.8
    );

    tree.options.branch.gnarliness[0] = 0.02;
    tree.options.branch.gnarliness[1] = linearTransformFloat(
      growthFactor,
      maxGrowthFactor / 3,
      (maxGrowthFactor / 3) * 2,
      0.09,
      0.2
    );
    tree.options.branch.gnarliness[2] = linearTransformFloat(
      growthFactor,
      maxGrowthFactor / 3,
      (maxGrowthFactor / 3) * 2,
      0.05,
      0.2
    );
    tree.options.branch.gnarliness[3] = 0.09;

    tree.options.leaves.angle = 55;
    tree.options.leaves.count = 16;
    tree.options.leaves.start = 0;
    tree.options.leaves.sizeVariance = 0.72;
    tree.options.leaves.alphaTest = 0.5

    tree.options.leaves.size = linearTransformFloat(
      growthFactor,
      maxGrowthFactor / 3,
      (maxGrowthFactor / 3) * 2,
      2.05,
      3
    );

    if (sceneType && controls && camera) {
      if (growthFactor <= maxGrowthFactor / 2) {
        if (sceneType === SceneQuality.Solo) {
          controls.target.set(0, 30, 0);
          camera.position.set(70, 50, 0);
        } else {
          controls.target.set(0, 20, 0);
          camera.position.set(40, 90, 0);
        }
      } else {
        if (sceneType === SceneQuality.Solo) {
          controls.target.set(0, 30, 0);
          camera.position.set(100, 100, 0);
        } else {
          controls.target.set(0, 20, 0);
          camera.position.set(40, 90, 0);
        }
      }
    }
  }

  // Level 3
  if (
    growthFactor >= (maxGrowthFactor / 3) * 2 &&
    growthFactor <= maxGrowthFactor
  ) {
    tree.options.branch.levels = 3;
    tree.options.branch.children[0] = 10;
    tree.options.branch.children[1] = 4;
    tree.options.branch.children[2] = 3;

    tree.options.branch.force.strength = -0.01087;

    tree.options.branch.angle[1] = 39;
    tree.options.branch.angle[2] = 39;
    tree.options.branch.angle[3] = 51;

    tree.options.branch.sections[0] = 12;
    tree.options.branch.sections[1] = 8;
    tree.options.branch.sections[2] = 4;
    tree.options.branch.sections[3] = 6;

    tree.options.branch.segments[0] = 8;
    tree.options.branch.segments[1] = 6;
    tree.options.branch.segments[2] = 4;
    tree.options.branch.segments[3] = 3;

    tree.options.branch.start[1] = 0.32;
    tree.options.branch.start[2] = 0.34;
    tree.options.branch.start[3] = 0;

    tree.options.branch.taper[0] = 0.7;
    tree.options.branch.taper[1] = 0.62;
    tree.options.branch.taper[2] = 0.76;
    tree.options.branch.taper[3] = 0;

    tree.options.branch.twist[0] = 0.09;
    tree.options.branch.twist[1] = -0.07;
    tree.options.branch.twist[2] = 0;
    tree.options.branch.twist[3] = 0;

    tree.options.branch.length[0] = linearTransformFloat(
      growthFactor,
      (maxGrowthFactor / 3) * 2,
      maxGrowthFactor,
      35,
      45
    );
    tree.options.branch.length[1] = linearTransformFloat(
      growthFactor,
      (maxGrowthFactor / 3) * 2,
      maxGrowthFactor,
      21,
      29.42
    );
    tree.options.branch.length[2] = linearTransformFloat(
      growthFactor,
      (maxGrowthFactor / 3) * 2,
      maxGrowthFactor,
      6.51,
      12.3
    );
    tree.options.branch.length[3] = linearTransformFloat(
      growthFactor,
      (maxGrowthFactor / 3) * 2,
      maxGrowthFactor,
      1,
      4.6
    );

    tree.options.branch.radius[0] = linearTransformFloat(
      growthFactor,
      (maxGrowthFactor / 3) * 2,
      maxGrowthFactor,
      2,
      3.03
    );
    tree.options.branch.radius[1] = 0.63
    tree.options.branch.radius[2] = 0.8
    tree.options.branch.radius[3] = linearTransformFloat(
      growthFactor,
      (maxGrowthFactor / 3) * 2,
      maxGrowthFactor,
      0.8,
      1.1
    );

    tree.options.branch.gnarliness[0] = -0.05;
    tree.options.branch.gnarliness[1] = 0.2;
    tree.options.branch.gnarliness[2] = 0.16;
    tree.options.branch.gnarliness[3] = 0.05;

    tree.options.leaves.angle = 30;
    tree.options.leaves.count = 10;
    tree.options.leaves.start = 0.01;
    tree.options.leaves.sizeVariance = 0.72;
    tree.options.leaves.alphaTest = 0.5

    tree.options.leaves.size = linearTransformFloat(
      growthFactor,
      (maxGrowthFactor / 3) * 2,
      maxGrowthFactor,
      3,
      4.62
    );

    if (sceneType && controls && camera) {
      if (sceneType === SceneQuality.Solo) {
        controls.target.set(0, 45, 0);
        camera.position.set(100, 100, 0);
      } else {
        controls.target.set(0, 40, 0);
        camera.position.set(70, 90, 0);
      }
    }
  }

  if (emulateCalendarLook) {
    tree.options.leaves.tint = getCalendarTint();
    tree.options.leaves.count = getCalendarLeavesCount(tree.options.leaves.count);
    tree.options.leaves.size = getCalendarLeavesSize(tree.options.leaves.size);
  }

  tree.generate();

  if (controls) {
    controls.update();
  }
}

export function calculatePineGrowth(
  tree: any,
  sceneType: SceneQuality | null,
  controls: any,
  camera: any,
  growthFactor: number,
  initialGrowPercent = 15,
  maxGrowthFactor: number,
) {
  if (growthFactor < 0) {
    throw new Error('Growth factor must be non negative integer');
  }

  if (initialGrowPercent > 0) {
    growthFactor += (maxGrowthFactor * initialGrowPercent) / 100;

    growthFactor =
      growthFactor <= maxGrowthFactor ? growthFactor : maxGrowthFactor;
  }

  tree.options.type = 'evergreen';
  tree.options.branch.levels = 1;
  tree.options.branch.angle[3] = 60;

  tree.options.branch.force.direction.x = 0;
  tree.options.branch.force.direction.y = 1;
  tree.options.branch.force.direction.z = 0;

  tree.options.branch.gnarliness[0] = 0.05;
  tree.options.branch.gnarliness[1] = 0.08;
  tree.options.branch.gnarliness[2] = 0;
  tree.options.branch.gnarliness[3] = 0;

  tree.options.branch.length[2] = 1;
  tree.options.branch.length[3] = 1;

  tree.options.branch.radius[2] = 0.7;
  tree.options.branch.radius[3] = 0.7;

  tree.options.branch.sections[0] = 12;
  tree.options.branch.sections[1] = 10;
  tree.options.branch.sections[2] = 8;
  tree.options.branch.sections[3] = 6;

  tree.options.branch.segments[0] = 8;
  tree.options.branch.segments[1] = 6;
  tree.options.branch.segments[2] = 4;
  tree.options.branch.segments[3] = 3;

  tree.options.branch.start[3] = 3;

  tree.options.branch.taper[0] = 0.7;
  tree.options.branch.taper[1] = 0.7;
  tree.options.branch.taper[2] = 0.7;
  tree.options.branch.taper[3] = 0.7;

  tree.options.branch.twist[0] = 0;
  tree.options.branch.twist[1] = 0;
  tree.options.branch.twist[2] = 0;
  tree.options.branch.twist[3] = 0;

  tree.options.leaves.tint = getRandomTint();
  tree.options.leaves.alphaTest = 0.3;

  // Level 1
  if (growthFactor < maxGrowthFactor / 3) {
    tree.options.branch.angle[1] = 117;
    tree.options.branch.angle[2] = 60;

    tree.options.branch.children[0] = linearTransformFloat(growthFactor, 1, maxGrowthFactor / 1, 0, 70);
    tree.options.branch.children[1] = 7;
    tree.options.branch.children[2] = 5;

    tree.options.branch.force.strength = 0;

    tree.options.branch.length[0] = linearTransformFloat(growthFactor, 1, maxGrowthFactor / 1, 5, 25);
    tree.options.branch.length[1] = linearTransformFloat(growthFactor, 1, maxGrowthFactor / 3, 0, 8.5)

    tree.options.branch.radius[0] = linearTransformFloat(growthFactor, 1, maxGrowthFactor / 3, 0.001, 0.3);
    tree.options.branch.radius[1] = linearTransformFloat(growthFactor, 1, maxGrowthFactor / 3, 0.001, 0.3);

    tree.options.branch.start[1] = linearTransformFloat(growthFactor, 1, maxGrowthFactor / 3, 0.16, 0.27);
    tree.options.branch.start[2] = 0.3;

    tree.options.leaves.angle = 10;
    tree.options.leaves.count = 21;
    tree.options.leaves.start = linearTransformFloat(growthFactor, 1, maxGrowthFactor / 3, 0.001, 0.09);
    tree.options.leaves.size = linearTransformFloat(growthFactor, 1, maxGrowthFactor / 3, 0.01, 0.5);
    tree.options.leaves.sizeVariance = 0.7;

    if (sceneType && controls && camera) {
      if (growthFactor <= maxGrowthFactor / 6) {
        if (sceneType === SceneQuality.Solo) {
          controls.target.set(0, 10, 0);
          camera.position.set(20, 15, 0);
        } else {
          controls.target.set(0, 10, 0);
          camera.position.set(30, 20, 0);
        }
      } else {
        if (sceneType === SceneQuality.Solo) {
          controls.target.set(0, 15, 0);
          camera.position.set(45, 30, 0);
        } else {
          controls.target.set(0, 15, 0);
          camera.position.set(40, 20, 0);
        }
      }
    }
  }

  // Level 2
  if (
    growthFactor >= maxGrowthFactor / 3 &&
    growthFactor <= (maxGrowthFactor / 3) * 2
  ) {
    tree.options.branch.angle[1] = 110;
    tree.options.branch.angle[2] = 16;

    tree.options.branch.children[0] = linearTransformFloat(growthFactor, 1, maxGrowthFactor / 3, 70, 95);
    tree.options.branch.children[1] = 3;
    tree.options.branch.children[2] = 5;

    tree.options.branch.force.strength = -0.003;

    tree.options.branch.length[0] = linearTransformFloat(
      growthFactor,
      maxGrowthFactor / 3,
      (maxGrowthFactor / 3) * 2,
      25,
      51.2);
    tree.options.branch.length[1] = linearTransformFloat(
      growthFactor,
      maxGrowthFactor / 3,
      (maxGrowthFactor / 3) * 2,
      8.5,
      31)

    tree.options.branch.radius[0] = linearTransformFloat(
      growthFactor,
      maxGrowthFactor / 3,
      (maxGrowthFactor / 3) * 2,
      0.3,
      1);
    tree.options.branch.radius[1] = linearTransformFloat(growthFactor, 1, maxGrowthFactor / 3, 0.3, 0.36);

    tree.options.branch.start[1] = linearTransformFloat(
      growthFactor,
      maxGrowthFactor / 3,
      (maxGrowthFactor / 3) * 2,
      0.27,
      0.3);
    tree.options.branch.start[2] = 0.14;

    tree.options.leaves.angle = 39;
    tree.options.leaves.count = 15;
    tree.options.leaves.start = 0;
    tree.options.leaves.size = linearTransformFloat(
      growthFactor,
      maxGrowthFactor / 3,
      (maxGrowthFactor / 3) * 2,
      0.5,
      2.1);

    tree.options.leaves.sizeVariance = 0.7;

    if (sceneType && controls && camera) {
      if (growthFactor <= maxGrowthFactor / 2) {
        if (sceneType === SceneQuality.Solo) {
          controls.target.set(0, 30, 0);
          camera.position.set(70, 50, 0);
        } else {
          controls.target.set(0, 20, 0);
          camera.position.set(40, 90, 0);
        }
      } else {
        if (sceneType === SceneQuality.Solo) {
          controls.target.set(0, 30, 0);
          camera.position.set(100, 100, 0);
        } else {
          controls.target.set(0, 20, 0);
          camera.position.set(40, 90, 0);
        }
      }
    }
  }

  // Level 3
  if (
    growthFactor >= (maxGrowthFactor / 3) * 2 &&
    growthFactor <= maxGrowthFactor
  ) {
    tree.options.branch.angle[1] = 129;
    tree.options.branch.angle[2] = 16;

    tree.options.branch.children[0] = 100;
    tree.options.branch.children[1] = 3;
    tree.options.branch.children[2] = 0;

    tree.options.branch.force.strength = 0.009;

    tree.options.branch.length[0] = linearTransformFloat(growthFactor, (maxGrowthFactor / 3) * 2, maxGrowthFactor, 51.2, 70);
    tree.options.branch.length[1] = linearTransformFloat(growthFactor, (maxGrowthFactor / 3) * 2, maxGrowthFactor, 31, 38.3)

    tree.options.branch.radius[0] = linearTransformFloat(growthFactor, (maxGrowthFactor / 3) * 2, maxGrowthFactor, 1, 1.3);
    tree.options.branch.radius[1] = 0.36;

    tree.options.branch.start[1] = linearTransformFloat(growthFactor, (maxGrowthFactor / 3) * 2, maxGrowthFactor, 0.3, 0.33);
    tree.options.branch.start[2] = 0.14;

    tree.options.leaves.angle = 17;
    tree.options.leaves.count = 18;
    tree.options.leaves.start = 0.09
    tree.options.leaves.size = linearTransformFloat(growthFactor, (maxGrowthFactor / 3) * 2, maxGrowthFactor, 2.1, 3.7);
    tree.options.leaves.sizeVariance = 0.7;

    if (sceneType && controls && camera) {
      if (sceneType === SceneQuality.Solo) {
        controls.target.set(0, 45, 0);
        camera.position.set(100, 100, 0);
      } else {
        controls.target.set(0, 40, 0);
        camera.position.set(70, 90, 0);
      }
    }
  }

  tree.generate();
  if (controls) {
    controls.update();
  }
}

function linearTransformFloat(value: number, inMin: number, inMax: number, outMin: number, outMax: number) {
  if (value < inMin) value = inMin;
  if (value > inMax) value = inMax;

  return ((value - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
}

function getRandomGrayValue() {
  const min = 200;
  const max = 255;
  const randomValue = Math.floor(Math.random() * (max - min + 1)) + min;
  return randomValue;
}

function getRandomTint() {
  const grayValue = getRandomGrayValue();
  const value = (grayValue << 16) + (grayValue << 8) + grayValue;

  return value;
}

function getCalendarTint() {
  const calendarValue = getTintValueBasedOnDate();
  const result = (255 << 16) + (calendarValue << 8) + calendarValue;

  return result;
}

function getTintValueBasedOnDate() {
  const now = new Date();
  const month = now.getMonth();
  let value;

  if (month >= 3 && month <= 7) { // April (3) August (7)
    value = 255;
  } else if (month >= 8 && month <= 11) { // September (8) to December (11)
    // Calculate the decreasing proportion
    const monthsSinceSeptember = month - 8; // 0 for Sep, 1 for Oct, ..., 3 for Dec
    const decreaseAmount = (255 - 120) / 4; // Total decrease from 255 to 120 over 4 months
    value = 255 - (decreaseAmount * monthsSinceSeptember);
  } else {
    // If it's January, February, or March, return the minimum value
    value = 120;
  }

  return Math.round(value);
}

function getCalendarLeavesCount(originalValue: number) {
  const now = new Date();
  const month = now.getMonth();
  let decreasePercentage = 0;

  if (month === 4 || month === 5 || month === 6 || month === 7) {
    // May (4), June (5), July (6), August (7) - no decrease
    return originalValue;
  } else if (month === 8) { // September (8)
    decreasePercentage = 0.20; // 20%
  } else if (month === 9) { // October (9)
    decreasePercentage = 0.40; // 40%
  } else if (month === 10) { // November (10)
    decreasePercentage = 0.70; // 70%
  } else if (month === 11) { // December (11)
    decreasePercentage = 0.80; // 80%
  } else if (month === 0) { // January ()
    decreasePercentage = 0.90; // 90%
  } else if (month === 1) { // February (1)
    decreasePercentage = 1.00; // 100%
  } else if (month === 2) { // March (2)
    decreasePercentage = 0.70; // 70%
  } else if (month === 3) { // April (3)
    decreasePercentage = 0.30; // 30%
  }

  const decreasedValue = originalValue * (1 - decreasePercentage);

  return Math.round(decreasedValue);
}

function getCalendarLeavesSize(originalValue: number) {
  const now = new Date();
  const month = now.getMonth();

  let decreasePercentage = 0;
  if (month === 6 || month === 7 || month === 8 || month === 9 || month === 10 || month === 11) {
    // July (6), August (7), September (8), November (10), December (), January (0), February (1) - no decrease
    return originalValue;
  } else if (month === 2) { // March (2)
    decreasePercentage = 0.90; // 90%
  } else if (month === 3) { // April (3)
    decreasePercentage = 0.60; //60%
  } else if (month === 4) { // May (4)
    decreasePercentage = 0.40; // 40%
  } if (month === 5) { // June (5)
    decreasePercentage = 0.20; // 20%
  }

  const decreasedValue = originalValue * (1 - decreasePercentage);

  return Math.round(decreasedValue);
}