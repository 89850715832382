let promises: any = {};

export function clear(key: string, duration: number) {
  duration = duration || 0;

  if (duration === -1) {
    return delete promises[key];
  }

  setTimeout(() => {
    delete promises[key];
  }, duration * 1000);
}

export async function share(key: string, func: () => Promise<any>, duration: number) {
  if (promises[key]) {
    return await promises[key];
  }
  try {
    const promise = func();
    promises[key] = promise;
    const result = await promise;
    clear(key, duration);
    return result;
  } catch (err) {
    clear(key, 0);
    throw err;
  }
}

export function get(key: string) {
  return promises[key];
}

export function clearAll() {
  promises = {};
}

export const getQueryString = (params: { key: string, value: string | number }[]) => {
  if (!params || !params.length) {
    return "";
  }
  return params.map((x) => `${x.key}=${x.value}`).join("&");
}

export const applyQueryParams = (url: string, params: { key: string, value: string | number }[]) => {
  const queryString = getQueryString(params);
  if (!queryString) {
    return url;
  }
  const endsWithQuestionMark = url.endsWith("?");
  if (endsWithQuestionMark) {
    return `${url}${queryString}`;
  }
  if (url.indexOf("?") > 0) {
    return `${url}&${queryString}`;
  }
  return `${url}?${queryString}`;
}