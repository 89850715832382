import { useMemo } from "react";
import { useAppSelector } from "../store/hooks";

export const useServerTranslate = (key: string, defaultValue: string) => {
  const translations = useAppSelector(state => state.translations);
  const translated = useMemo(() => {
    return translations.translations[key] || translations.fallbackTranslations[key] || defaultValue;
  }, [defaultValue, key, translations.translations, translations.fallbackTranslations]);
  return translated;
}

