import { useCallback, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { processTask } from '../../../api/auth';
import { handleErrors, openLink } from '../../../core/helpers';
import useFormattedAsset from '../../../hooks/useFormattedAsset';
import { IBoostChannelTaskItem, IProcessTask, } from '../../../types/types';
import TaskItem from '../taskItem';

import { ReactComponent as TelegramBoostIcon } from '../../../assets/tasks/TelegramBoostIcon.svg';
import { useTranslation } from 'react-i18next';

interface IProps {
  task: IBoostChannelTaskItem,
  onClick: (task: IProcessTask) => void,
}

const TaskBoostChannelItem = ({ task, onClick }: IProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const formattedAsset = useFormattedAsset(null, task.reward);
  const channelUrl = useMemo(() => {
    return `https://t.me/boost/${task.settings.channelId.replace('@', '')}`;
  }, [task.settings.channelId]);
  const { t } = useTranslation();
  const handleStart = useCallback(async () => {
    try {
      setIsLoading(true);
      const res = await processTask(task.id);
      if (res.status === 'RewardClaimed') {
        toast(t('toasts.defaultReplenishText', { replenishAmount: `${formattedAsset.amount} ${formattedAsset.name}` }));
      }
      onClick(res);
      openLink(channelUrl);
    } catch (err: any) {
      handleErrors(err);
    } finally {
      setIsLoading(false);
    }
  }, [onClick, task.id, channelUrl, formattedAsset.amount, formattedAsset.name, t]);

  const handleVerify = useCallback(async () => {
    try {
      setIsLoading(true);
      const res = await processTask(task.id);
      if (res.status === 'RewardClaimed') {
        toast(t('toasts.defaultReplenishText', { replenishAmount: `${formattedAsset.amount} ${formattedAsset.name}` }));
      }
      onClick(res);
    } catch (err: any) {
      handleErrors(err);
      openLink(channelUrl);
    } finally {
      setIsLoading(false);
    }
  }, [onClick, task.id, formattedAsset.amount, formattedAsset.name, channelUrl, t]);

  const handleClick = useCallback(() => {
    if (task.operation === 'Start') {
      return handleStart();
    }
    if (task.operation === 'Verify') {
      return handleVerify();
    }
  }, [handleStart, handleVerify, task.operation]);

  return (
    <TaskItem task={task} isLoading={isLoading} status={task.status} leftIcon={<TelegramBoostIcon />} description={`+ ${formattedAsset.amount} ${formattedAsset.name}`} onClick={handleClick} />
  );
}

export default TaskBoostChannelItem;
