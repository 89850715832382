'use client'

import { Provider } from 'react-redux'
import { store } from '../store'
import SetupStore from './SetupStore'

export interface StoreProviderProps {
  children: React.ReactNode,
  onSetup: () => void
}

export default function StoreProvider({ onSetup, children }: StoreProviderProps) {
  return <Provider store={store}>
    <SetupStore onSetup={onSetup} />
    {children}
  </Provider>
}