import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ITranslation } from '../../types/types'
import { handleErrors } from '../../core/helpers'
import { getTranslations } from '../../api/auth'

const tg = window.Telegram?.WebApp;
const lng = tg?.initDataUnsafe?.user?.language_code;

interface ITranslations {
  translations: ITranslation[],
  fallbackTranslations: ITranslation[]
}

export const fetchTranslations = createAsyncThunk<ITranslations, undefined, { rejectValue: string }>(
  'translations/fetchTranslations',
  async (_, { rejectWithValue }) => {
    try {
      const [translations, fallbackTranslations] = await Promise.all([getTranslations(lng), getTranslations('en')]);
      return { translations, fallbackTranslations };
    } catch (err: any) {
      handleErrors(err);
      return rejectWithValue(err.message);
    }
  }
)

interface ITranslationsState {
  fallbackTranslations: { [key: string]: string },
  translations: { [key: string]: string }
}

const initialState: ITranslationsState = {
  fallbackTranslations: {},
  translations: {},
}

export const translationsSlice = createSlice({
  name: 'translations',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchTranslations.fulfilled, (state, action) => {
      const translations = action.payload.translations.reduce((a, c) => {
        a[c.key] = c.value;
        return a;
      }, {} as { [key: string]: string });
      state.translations = translations;

      const fallbackTranslations = action.payload.fallbackTranslations.reduce((a, c) => {
        a[c.key] = c.value;
        return a;
      }, {} as { [key: string]: string });
      state.translations = translations;
      state.fallbackTranslations = fallbackTranslations;
    })
  }
})

export default translationsSlice.reducer;