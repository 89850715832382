
import { useMemo, useState } from 'react';
import { TIME_OPTIONS } from '../../../core/wallet';
import { ITransaction } from '../../../types/types';
import DialogTransactionInfo from '../dialogTransactionInfo';
import useFormattedAsset from '../../../hooks/useFormattedAsset';

import { ReactComponent as IncomeIcon } from '../../../assets/wallet/IncomeIcon.svg';
import { ReactComponent as OutcomeIcon } from '../../../assets/wallet/OutcomeIcon.svg';
import { ReactComponent as PlusIcon } from '../../../assets/wallet/PlusIcon.svg';
import { ReactComponent as MinusIcon } from '../../../assets/wallet/MinusIcon.svg';
import { ReactComponent as RightArrowIcon } from '../../../assets/wallet/RightArrowIcon.svg';

import styles from './TransactionListItem.module.scss'
import Typography from '../../common/typography';
interface TransactionListItemProps {
  transaction: ITransaction,
  address: string,
}

export default function TransactionItem({ transaction, address }: TransactionListItemProps) {
  const [open, setOpen] = useState(false);
  const date = useMemo(() => new Date(transaction.dateTime), [transaction.dateTime]);
  const formattedAsset = useFormattedAsset(null, transaction.amount);
  const formattedFee = useFormattedAsset(null, transaction.fee);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {open && <DialogTransactionInfo transaction={transaction} amount={formattedAsset.amount} assetName={formattedAsset.name} fee={formattedFee.amount} handleClose={handleClose} />}
      <div onClick={handleOpen} className={styles.transaction_item__wrapper} >
        <div className={styles.transaction_item}>
          <div className={styles.left_icon__container}>
            {address === transaction.addressFrom ? <OutcomeIcon /> : <IncomeIcon />}
            <div className={styles.left_subicon_container}>
              {address === transaction.addressFrom ? <MinusIcon /> : <PlusIcon />}
            </div>
          </div>
          <div className={styles.transaction_info__wrapper}>
            <div className={styles.transaction_info}>
              <Typography variant='body1' color='white'>{formattedAsset.amount} {formattedAsset.name}</Typography>
              <div className={styles.transaction_date}>
                <Typography variant='caption1' color='primary'>{transaction.status}</Typography>
              </div>
            </div>
            <Typography variant='caption1' color='secondary'>{date.toLocaleTimeString('en-US', TIME_OPTIONS)}</Typography>
          </div>
          <RightArrowIcon />
        </div>
      </div>
    </>
  );
} 