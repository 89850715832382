import { useCallback, useEffect, useState } from 'react';
import DialogDailyRewards from '../../components/dialogDailyRewards';

import TreeHeader from '../../components/tree/treeHeader';
import storage from '../../core/storage';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { fetchAccount } from '../../store/reducers/accountSlice';
import { SceneQuality } from '../../types/types';
import TreeNew from './Tree';
import TreeOld from './TreeOld';

import { Cache } from 'three';
Cache.enabled = true;

const TreePage = () => {
  const [type, setType] = useState<SceneQuality | null>(null);
  const account = useAppSelector(state => state.account.account);
  const dispatch = useAppDispatch();

  const initSceneType = useCallback(async () => {
    const type = await storage.getItem(process.env.REACT_APP_CLOUD_SCENE_TYPE_KEY as string);
    if (!type || type === SceneQuality.Colorfull) {
      return setType(SceneQuality.Medium);
    }
    if (type === SceneQuality.Default) {
      return setType(SceneQuality.Solo);
    }
    setType(type as SceneQuality);
  }, []);

  useEffect(() => {
    dispatch(fetchAccount());
    initSceneType();
  }, [dispatch, initSceneType]);

  return (
    <div id='tree' style={{ position: 'relative' }}>
      {account.hasDailyReward && <DialogDailyRewards />}
      <TreeHeader />
      {type && type !== SceneQuality.Solo && <TreeNew type={type} />}
      {type && type === SceneQuality.Solo && <TreeOld type={type} />}
    </div>
  );
}

export default TreePage;

