import React from 'react';

import styles from './Divider.module.scss';

const Divider = () => {
  return (
    <div className={styles.divider}></div>
  );
}

export default Divider;
