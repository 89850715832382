
import styles from './DialogReferralSystem.module.scss';
import { useTranslation } from 'react-i18next';
import Dialog from '../../common/dialog';
import Typography from '../../common/typography';

interface IProps {
  handleClose: () => void,
}

const firstSectionItems = [1, 2, 3, 4, 5, 6, 7, 8];
const secondSectionItems = [1, 2, 3, 4, 5];

const DialogReferralSystem = ({ handleClose }: IProps) => {
  const { t } = useTranslation();

  return (
    <Dialog
      onClose={handleClose}
      title={t('pages.friends.sections.description.popup.title')}
    >
      <div className={styles.dialog__content}>
        <div className={styles.section}>
          <Typography variant='title2' color='white'>{t('pages.friends.sections.description.popup.sections.howToStart.title')}</Typography>
          <Typography variant='body1' color='white'>{t('pages.friends.sections.description.popup.sections.howToStart.description')}</Typography>
        </div>
        <div className={styles.section}>
          <Typography variant='title2' color='white'>{t('pages.friends.sections.description.popup.sections.howItWorks.title')}</Typography>
          <Typography variant='body1' color='white'>{t('pages.friends.sections.description.popup.sections.howItWorks.description')}</Typography>

          <Typography variant='body1' color='white'>{t('pages.friends.sections.description.popup.sections.howItWorks.sections.first.title')}</Typography>
          <ul>
            {firstSectionItems.map((item) => <li>
              <Typography variant='body1' color='white'>
                {t(`pages.friends.sections.description.popup.sections.howItWorks.sections.first.listItems.${item}`)}
              </Typography>
            </li>)}
          </ul>
          <Typography variant='body1' color='white'>{t('pages.friends.sections.description.popup.sections.howItWorks.sections.first.secondTitle')}</Typography>

          <Typography variant='body1' color='white'>{t('pages.friends.sections.description.popup.sections.howItWorks.sections.second.title')}</Typography>
          <ul>
            {secondSectionItems.map((item) => <li>
              <Typography variant='body1' color='white'>
                {t(`pages.friends.sections.description.popup.sections.howItWorks.sections.second.listItems.${item}`)}
              </Typography>
            </li>)}
          </ul>
          <Typography variant='body1' color='white'>{t('pages.friends.sections.description.popup.sections.howItWorks.sections.second.secondTitle')}</Typography>
          <div>
            <Typography variant='body1' color='white'>{t('pages.friends.sections.description.popup.sections.howItWorks.sections.third.title')}</Typography>
            <Typography variant='body1' color='white'>{t('pages.friends.sections.description.popup.sections.howItWorks.sections.third.secondTitle')}</Typography>
          </div>
        </div>
        <div className={styles.section}>
          <Typography variant='title2' color='white'>{t('pages.friends.sections.description.popup.sections.howToClaim.title')}</Typography>
          <Typography variant='body1' color='white'>{t('pages.friends.sections.description.popup.sections.howToClaim.description')}</Typography>
        </div>
      </div>
    </Dialog>
  );
}

export default DialogReferralSystem;
