import { useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { processTask } from '../../../api/auth';
import { handleErrors } from '../../../core/helpers';
import useFormattedAsset from '../../../hooks/useFormattedAsset';
import { IConnectTonWalletTask, IConnectTonWalletTaskItem, IProcessTask, } from '../../../types/types';
import TaskItem from '../taskItem';

import { ReactComponent as TonIcon } from '../../../assets/tasks/TonIcon.svg';
import { useTranslation } from 'react-i18next';

import { useTonConnectUI, useTonWallet } from '@tonconnect/ui-react';
import { useAppSelector } from '../../../store/hooks';

interface IProps {
  task: IConnectTonWalletTaskItem,
  onClick: (task: IProcessTask) => void,
}

const TaskConnectTonWalletItem = ({ task, onClick }: IProps) => {
  const wallet = useTonWallet();
  const userTasks = useAppSelector(state => state.account.userTasks);
  const currentTask = useMemo(() => userTasks.find((item) => item.taskId === task.id), [task.id, userTasks]) as IConnectTonWalletTask;
  const [isLoading, setIsLoading] = useState(false);
  const [tonConnectUI] = useTonConnectUI();
  const formattedAsset = useFormattedAsset(null, task.reward);

  const { t } = useTranslation();

  const handleProcessTask = useCallback(async (payload?: any) => {
    const res = await processTask(task.id, payload) as IConnectTonWalletTask;
    if (res.status === 'RewardClaimed') {
      toast(t('toasts.defaultReplenishText', { replenishAmount: `${formattedAsset.amount} ${formattedAsset.name}` }));
    }
    onClick(res);
    return res;
  }, [formattedAsset.amount, formattedAsset.name, t, task.id, onClick]);

  const handleStart = useCallback(async () => {
    try {
      const res = await handleProcessTask();
      tonConnectUI.setConnectRequestParameters({
        state: "ready",
        value: { tonProof: res.state.payload },
      });
      if (wallet) {
        tonConnectUI.disconnect();
      }
      tonConnectUI.openModal();
    } catch (err: any) {
      handleErrors(err);
    } finally {
      setIsLoading(false);
    }
  }, [tonConnectUI, handleProcessTask, wallet]);

  const handleVerify = useCallback(async () => {
    try {
      setIsLoading(true);
      await handleProcessTask();
    } catch (err: any) {
      if (wallet) {
        tonConnectUI.disconnect();
      }
      await tonConnectUI.openModal();
    } finally {
      setIsLoading(false);
    }
  }, [tonConnectUI, handleProcessTask, wallet]);

  const handleClick = useCallback(() => {
    if (task.operation === 'Start') {
      return handleStart();
    }
    if (task.operation === 'Verify') {
      return handleVerify();
    }
  }, [handleStart, handleVerify, task.operation]);

  useEffect(() => {
    tonConnectUI.onStatusChange(async (wallet: any) => {
      if (
        wallet?.connectItems?.tonProof &&
        "proof" in wallet.connectItems.tonProof
      ) {
        try {
          const proof = wallet?.connectItems?.tonProof?.proof;
          const payload = {
            Type: 'ConnectTonWallet',
            address: wallet.account.address,
            network: wallet.account.chain,
            publicKey: wallet.account.publicKey,
            proof: { ...proof, stateInit: wallet.account.walletStateInit }
          }
          await handleProcessTask(payload);
        } catch (err: any) {
          handleErrors(err);
        }
      }
    });
  }, [tonConnectUI, handleProcessTask]);

  useEffect(() => {
    if (!currentTask?.state?.payload) {
      return;
    }
    tonConnectUI.setConnectRequestParameters({
      state: "ready",
      value: { tonProof: currentTask.state.payload },
    });
  }, [currentTask?.state?.payload, tonConnectUI]);

  return (
    <TaskItem task={task} isLoading={isLoading} status={task.status} leftIcon={<TonIcon />} description={`+ ${formattedAsset.amount} ${formattedAsset.name}`} onClick={handleClick} />

  );
}

export default TaskConnectTonWalletItem;
