import React, { useCallback, useEffect, useMemo, useState } from 'react';
import IconButton from '../../common/iconButton';
import LeftSideIcon from '../../common/leftSideIcon';
import Typography from '../../common/typography';

import { ReactComponent as AlarmClockIcon } from '../../../assets/AlarmClockIcon.svg';
import { ReactComponent as AlarmRingingIcon } from '../../../assets/AlarmRingingIcon.svg';

import styles from './RemindButton.module.scss';
import { useAppDispatch } from '../../../store/hooks';
import { remindReferral } from '../../../api/auth';
import { fetchReferrals } from '../../../store/reducers/accountSlice';
import { handleErrors } from '../../../core/helpers';
import ButtonLoader from '../../common/loaders/buttonLoader';
import { useReplenishTimer } from '../../../hooks/useReplenishTimer';
import { useTranslation } from 'react-i18next';

const remindStorageKey = 'lastRemind';
const claimRewardsTime = 5 * 60 * 1000;

const RemindButton = () => {
  const [lastRemind, setLastRemind] = useState('');

  const replenishTimer = useReplenishTimer(lastRemind, claimRewardsTime);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const rightIcon = useMemo(() => {
    if (isLoading) {
      return <ButtonLoader />;
    }
    if (replenishTimer) {
      return <AlarmRingingIcon style={{ stroke: 'var(--basic--secondary)' }} />;
    }
    return <AlarmRingingIcon />;
  }, [isLoading, replenishTimer]);

  const iconDisabled = useMemo(() => {
    if (isLoading || !!replenishTimer) {
      return true;
    }
    return false;
  }, [isLoading, replenishTimer]);

  const handleClick = useCallback(async () => {
    try {
      setIsLoading(true);
      const currentDate = new Date().toString();
      setLastRemind(currentDate);
      localStorage.setItem(remindStorageKey, currentDate);
      await remindReferral();
      await dispatch(fetchReferrals());
    } catch (err: any) {
      handleErrors(err);
    } finally {
      setIsLoading(false);
    }
  }, [dispatch]);

  useEffect(() => {
    const lastRemind = localStorage.getItem(remindStorageKey);
    setLastRemind(lastRemind || '');
  }, []);

  return (
    <div className={styles.task_item__wrapper}>
      <div className={styles.task_item}>
        <div style={{ maxWidth: '56px' }}>
          <LeftSideIcon type='big' icon={<AlarmClockIcon />} />
        </div>
        <div className={`${styles.task_info__wrapper} flex-break-word`}>
          <div className={styles.task_main_info__wrapper}>
            <Typography variant='body1' color='white'>{t('pages.friends.sections.remindFriends.title')}</Typography>
          </div>
          {replenishTimer && <Typography variant='caption1' color='secondary'>{t('pages.friends.sections.remindFriends.secondTitle', { replenishTimer })}</Typography>}
        </div>
        <div style={{ maxWidth: '56px' }}>
          <IconButton disabled={iconDisabled} onClick={handleClick}>{rightIcon}</IconButton>
        </div>
      </div>
    </div>
  );
}

export default RemindButton;
