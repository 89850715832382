import { useCallback, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { processTask } from '../../../api/auth';
import { handleErrors } from '../../../core/helpers';
import useFormattedAsset, { getFormattedAmount } from '../../../hooks/useFormattedAsset';
import { IProcessTask, IProgressTaskItem } from '../../../types/types';
import TaskItem from '../taskItem';
import { useAppSelector } from '../../../store/hooks';

import { ReactComponent as GrowIcon } from '../../../assets/tasks/GrowIcon.svg';
import { ReactComponent as DropIcon } from '../../../assets/friends/DropIcon.svg';
import { useTranslation } from 'react-i18next';

interface IProps {
  task: IProgressTaskItem,
  onClick: (task: IProcessTask) => void,
}

const TaskProgressItem = ({ task, onClick }: IProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const account = useAppSelector(state => state.account.account);
  const formattedAsset = useFormattedAsset(null, task.reward);
  const assetName = useAppSelector(state => state.settings.assetNames.Score);
  const asset = useAppSelector(state => state.settings.settings.assets.find((item) => item.name === assetName));

  const { t } = useTranslation();

  const leftIcon = useMemo(() => {
    if (task.settings.measure === 'Score') {
      return <GrowIcon />
    }
    return <DropIcon />
  }, [task.settings.measure]);

  const completeText = useMemo(() => {
    if (task.settings.measure === 'Score') {
      return `${getFormattedAmount(asset, account.score)} / ${getFormattedAmount(asset, task.settings.value)}`
    }
    return `${account.replenishCount} / ${task.settings.value}`;
  }, [account.replenishCount, account.score, task.settings.measure, task.settings.value, asset]);

  const handleClick = useCallback(async () => {
    try {
      setIsLoading(true);
      const res = await processTask(task.id);
      toast(t('toasts.defaultReplenishText', { replenishAmount: `${formattedAsset.amount} ${formattedAsset.name}` }));
      onClick(res);
    } catch (err: any) {
      handleErrors(err);
    } finally {
      setIsLoading(false);
    }
  }, [formattedAsset.amount, formattedAsset.name, onClick, task.id, t]);

  return (
    <TaskItem task={task} isLoading={isLoading} status={task.status} completeText={completeText} leftIcon={leftIcon} description={`+ ${formattedAsset.amount} ${formattedAsset.name}`} onClick={handleClick} />
  );
}

export default TaskProgressItem;
