import { useCallback } from 'react';
import { useState } from 'react';
import { handleErrors } from '../../../core/helpers';
import Button from '../../common/button';
import CustomInput from '../../common/customInput';
import Dialog from '../../common/dialog';
import ButtonLoader from '../../common/loaders/buttonLoader';

import styles from './DialogVerifyProtectedForm.module.scss';

interface IProps {
  handleClose: () => void,
  onClick: (code: string) => void,
}

const DialogVerifyProtectedForm = ({ handleClose, onClick }: IProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [code, setCode] = useState<string>('');

  const handleClick = useCallback(async () => {
    setIsDirty(true);
    if (!code) {
      return;
    }
    try {
      setIsLoading(true);
      onClick(code);
    } catch (err: any) {
      handleErrors(err);
    } finally {
      setIsLoading(false);
    }
  }, [code, onClick]);

  return (
    <Dialog
      onClose={handleClose}
      title='Verify'
    >
      <div className={styles.dialog__content}>
        <CustomInput
          type='text'
          title='Enter code from video'
          value={code}
          onChange={(e) => setCode(e.target.value)}
          error={isDirty && !code}
          helperText={isDirty && !code ? `Field can't be empty!` : null}
        />
        <Button
          onClick={handleClick}
          disabled={isLoading}
          endIcon={isLoading ? <ButtonLoader /> : null}
        >
          Verify
        </Button>
      </div>
    </Dialog>
  );
}

export default DialogVerifyProtectedForm;
