import { Fragment, useEffect, useMemo, useState } from 'react';
import FriendItem from "../../components/friends/friendItem";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import CopyButton from "../../components/common/copyButton";
import Typography from '../../components/common/typography';

import styles from './Friends.module.scss';
import List from '../../components/tasks/list';
import Divider from '../../components/common/divider';
import Button from '../../components/common/button';

import RewardButton from '../../components/friends/rewardButton';
import { fetchReferrals } from '../../store/reducers/accountSlice';
import { IReferal, LinkType } from '../../types/types';
import PageWrapper from '../../components/common/pageWrapper';
import PageContentWrapper from '../../components/common/pageContentWrapper';
import useReferralLink from '../../hooks/useReferralLink';
import { useTranslation } from 'react-i18next';
import { openLink } from '../../core/helpers';
import DialogReferralSystem from '../../components/friends/dialogReferralSystem';
import RemindButton from '../../components/friends/remindButton';

const Friends = () => {
  const [isOpen, setIsOpen] = useState(false);
  const friends = useAppSelector(state => state.account.referals.data);
  const friendsAmount = useAppSelector(state => state.account.referals.total);

  const bonuses = useMemo(() => {
    switch (true) {
      case (friendsAmount < 99):
        return { balance: '5%', score: '2.5%' };
      case (friendsAmount < 499):
        return { balance: '4%', score: '2%' };
      case (friendsAmount < 999):
        return { balance: '3%', score: '1.5%' };
      case (friendsAmount < 4999):
        return { balance: '2%', score: '1%' };
      case (friendsAmount > 5000):
        return { balance: '1%', score: '0.5%' };
      default:
        return { balance: '5%', score: '2.5%' };
    }
  }, [friendsAmount]);

  const { t } = useTranslation();

  const shareLink = useReferralLink(LinkType.Share);
  const copyLink = useReferralLink(LinkType.Copy);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchReferrals());
  }, [dispatch]);

  return (
    <PageWrapper>
      <PageContentWrapper>
        <>
          {isOpen && <DialogReferralSystem handleClose={() => setIsOpen(false)} />}
          <Typography style={{ textAlign: 'center' }} variant='title2' color='white'>{t('pages.friends.sections.main.title')}</Typography>
          <RewardButton />
          <div className={styles.ref_info}>
            <Typography variant='body1' color='white'>{t('pages.friends.sections.description.refDescription', { balance: bonuses.balance, score: bonuses.score })}</Typography>
            <Typography style={{ cursor: 'pointer' }} variant='subheadline2' color='primary'>
              <div onClick={() => setIsOpen(true)}>
                {t('pages.friends.sections.description.readMore')}
              </div>
            </Typography>
          </div>
          <RemindButton />
          <List
            items={friends}
            title={t('pages.friends.sections.friends.title', { friendsAmount })}
            emptyListMessage={t('emptyListText')}
            renderItem={(item: IReferal, index: number) =>
              <Fragment key={item.id}>
                <FriendItem friend={item} />
                {index !== friends.length - 1 && <Divider />}
              </Fragment>}
          />
          <div className={styles.buttons__container}>
            <div style={{ width: '100%', textDecoration: 'none' }} onClick={() => openLink(shareLink)}>
              <Button style={{ boxShadow: "0px 0px 10px 0px #0000001A, 0px 0px 40px 0px #00000033" }}>
                {t('pages.friends.buttons.invite.title')}
              </Button>
            </div>
            <CopyButton data={copyLink} style={{ boxShadow: "0px 0px 10px 0px #0000001A, 0px 0px 40px 0px #00000033" }} />
          </div>
        </>
      </PageContentWrapper>
    </PageWrapper>
  );
}

export default Friends;
