import React from 'react';

interface IProps {
  src: string,
  alt: string,
  style?: object,
}

const AnimatedGif = ({ src, alt, style }: IProps) => {
  return (
    <img src={src} alt={alt} style={style} />
  );
}

export default AnimatedGif;
