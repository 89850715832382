
import { IRank, RankType } from '../../../types/types';
import { useAppSelector } from '../../../store/hooks';
import Typography from '../../common/typography';
import { InView } from "react-intersection-observer";

import styles from './RankItem.module.scss';

import { ReactComponent as TreeScoreIcon } from '../../../assets/friends/TreeScoreIcon.svg';
import { ReactComponent as RankDropIcon } from '../../../assets/RankDropIcon.svg';
import { ReactComponent as ReferralIcon } from '../../../assets/ReferralIcon.svg';
import { useMemo } from 'react';
import useFormattedAsset from '../../../hooks/useFormattedAsset';

interface RankItemProps {
  rank: IRank,
  type: RankType,
  position: number,
  isOwn?: boolean,
  onIntersect: (inView: boolean) => void,
}

const RankItem = ({ rank, type, position, isOwn = false, onIntersect }: RankItemProps) => {
  return (
    <>
      {isOwn ?
        <InView threshold={0.5} as="div" onChange={(inView, entry) => onIntersect(inView)} className={`${isOwn ? styles.rank_own_item__wrapper : styles.rank_item__wrapper}`}>
          <RankItemContent rank={rank} type={type} position={position} isOwn={isOwn} onIntersect={onIntersect} />
        </InView>
        :
        <div className={`${isOwn ? styles.rank_own_item__wrapper : styles.rank_item__wrapper}`}>
          <RankItemContent rank={rank} type={type} position={position} isOwn={isOwn} onIntersect={onIntersect} />
        </div>}
    </>
  );
}

const RankItemContent = ({ rank, type, position, isOwn = false, onIntersect }: RankItemProps) => {
  const assets = useAppSelector(state => state.settings.settings.assets);
  const scoreAsset = useMemo(() => {
    return assets.find((item) => item.type === 'Score');
  }, [assets]);
  const formattedAsset = useFormattedAsset(scoreAsset?.assetId || null, rank.value);
  const rankIcon = useMemo(() => {
    switch (type) {
      case 'score':
        return <TreeScoreIcon />;
      case 'referrals':
        return <ReferralIcon />;
      case 'replenishCount':
        return <RankDropIcon />;
    }
  }, [type]);
  return (
    <>
      <Typography variant='body1' color='white'>{position}</Typography>
      <div className='flex-break-word' style={{ width: '100%' }}>
        <Typography variant='body1' color='white'>{rank.username}</Typography>
      </div>
      <div className={styles.rank_score__wrapper}>
        {rankIcon}
        <Typography variant='subheadline2' color='white'>{type === 'score' ? formattedAsset.amount : rank.value}</Typography>
      </div>
    </>
  );
}

export default RankItem;
