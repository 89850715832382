import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { IAddressBalance, ITransaction, } from '../../types/types'
import { handleErrors } from '../../core/helpers';
import { getBalance, getTransactions } from '../../api/wallet';

interface ITransactions {
  data: ITransaction[],
  total: number,
}

export const fetchBalance = createAsyncThunk<IAddressBalance, string, { rejectValue: string }>(
  'wallet/fetchBalance',
  async (address, { rejectWithValue }) => {
    try {
      return await getBalance(address);
    } catch (err: any) {
      handleErrors(err);
      return rejectWithValue(err.message);
    }
  }
);

export const fetchTransactions = createAsyncThunk<ITransactions, string, { rejectValue: string }>(
  'wallet/fetchTransactions',
  async (address, { rejectWithValue }) => {
    try {
      return await getTransactions(address);
    } catch (err: any) {
      handleErrors(err);
      return rejectWithValue(err.message);
    }
  }
);

interface WalletState {
  balance: IAddressBalance,
  transactions: ITransactions
}

const initialState: WalletState = {
  balance: {
    outgoing: 0,
    pending: 0,
    stable: 0
  } as IAddressBalance,
  transactions: {
    data: [],
    total: 0,
  },
}

export const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(fetchBalance.fulfilled, (state, action) => {
        state.balance = action.payload;
      })
      .addCase(fetchTransactions.fulfilled, (state, action) => {
        state.transactions = action.payload;
      })
  }
})

export default walletSlice.reducer;