import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../store/hooks";
import { LinkType } from "../types/types";

const shareUrl = 'https://t.me/share/url';

const useReferralLink = (type: LinkType) => {
  const miniAppUrl = useAppSelector(state => state.settings.settings.social.miniAppUrl);
  const accountId = useAppSelector(state => state.account.account.id);
  const { t } = useTranslation();
  return useMemo(() => {
    if (type === LinkType.Copy) {
      return `${miniAppUrl}?startapp=ref${accountId}`;
    }
    return `${shareUrl}?url=${miniAppUrl}?startapp=ref${accountId}&text=${encodeURIComponent(t('pages.friends.buttons.invite.innerText'))}`
  }, [miniAppUrl, type, t, accountId]);
}

export default useReferralLink;