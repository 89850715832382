import { repeatable, unknownErrorMessage } from "../core/helpers";
import { IAddressBalance, ISendTransaction, INetworkSettings, ITransaction } from "../types/types";
import { share } from "./apiHelpers";

let apiUrl = '';

export const setHubUrl = (url: string) => {
  apiUrl = url;
}
export const getHubUrl = () => {
  return apiUrl;
}

export function doRequest(relativeUrl: string, config: any = {}): Promise<any> {
  const url = `${getHubUrl()}/${relativeUrl}`;
  return share(`makeApiCall:${url}#${JSON.stringify(config.body)}`, () => request(url, config), 0.5);
}

function postData<T>(relativeUrl: string, body?: T): Promise<any> {
  return doRequest(relativeUrl,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: body ? JSON.stringify(body) : undefined
    });
}

export async function request(url: string, config: any = {}): Promise<any> {
  config.headers = config.headers || {};
  config.headers["Content-Type"] = "application/json";
  try {
    const res = await fetch(url, config);
    let result;
    if (res.headers.get("Content-Type")?.indexOf("application/json") === 0) {
      result = await res.json();
    }
    if (res.ok) {
      return result;
    }
    throw { errors: result?.errors, message: unknownErrorMessage, statusCode: res.status };
  } catch (err: any) {
    throw err;
  }
}

export const getBalance = (address: string, assetId?: string): Promise<IAddressBalance> => repeatable(() => doRequest(`accounts/${address}/balance${assetId ? `/${assetId}` : ''}`));
export const getTransactions = (address: string): Promise<{ data: ITransaction[], total: number }> => repeatable(() => doRequest(`accounts/${address}/transactions?take=5&assetId=base`));
export const getSettings = (): Promise<INetworkSettings> => repeatable(() => doRequest(`settings`));
export const sendTransaction = (data: ISendTransaction) => postData(`transactions`, data);