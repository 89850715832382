import React, { useMemo } from 'react';

import styles from './SceneSettingsItem.module.scss';
import Typography from '../../common/typography';

import { ReactComponent as RadioButton } from '../../../assets/common/RadioButton.svg';
import { ReactComponent as RadioButtonActive } from '../../../assets/common/RadioButtonActive.svg';

interface IProps {
  isActive: boolean,
  title: string,
  onClick: () => void,
}

const SceneSettingsItem = ({ isActive, title, onClick }: IProps) => {
  const RadioButtonIcon = useMemo(() => {
    if (isActive) {
      return RadioButtonActive;
    }
    return RadioButton;
  }, [isActive]);
  return (
    <div onClick={onClick} className={`${styles.wrapper} ${isActive ? styles.active : ''}`}>
      <RadioButtonIcon className='importantSvg' style={{ minWidth: 20, minHeight: 20 }} />
      <Typography variant='body1' color='white'>{title}</Typography>
    </div>
  );
}

export default SceneSettingsItem;
