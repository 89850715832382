import { IWallet } from "../types/types";

let wallet: IWallet | null = null;

export function getWallet(mnemonic?: string) {
  if (wallet) {
    return wallet;
  }
  const mnemonicPhrase = mnemonic ? mnemonic.toLocaleLowerCase() : null;
  wallet = new window.Wallet(mnemonicPhrase);
  return wallet as IWallet;
}

export const getShortTransactionHash = (hash: string) => hash ? `${hash.slice(0, 10)}...` : 'null';
export const getShortAddressTransactionHash = (hash: string) => hash ? `${hash.slice(0, 10)}...${hash.slice(-10)}` : 'null';

export const TIME_OPTIONS = {
  year: 'numeric' as const,
  month: '2-digit' as const,
  day: '2-digit' as const,
  hour: '2-digit' as const,
  minute: '2-digit' as const,
};

export const isNumeric = (str: string) => {
  return /^\d+$/.test(str);
}

export const isCredentialsEncrypted = (credentials: string) => {
  return credentials.startsWith('{');
}