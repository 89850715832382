import React, { useCallback, useMemo, useState } from 'react';
import IconButton from '../../common/iconButton';
import LeftSideIcon from '../../common/leftSideIcon';
import Typography from '../../common/typography';

import { ReactComponent as CoinsIcon } from '../../../assets/tasks/CoinsIcon.svg';
import { ReactComponent as CoinsDisabledIcon } from '../../../assets/tasks/CoinsDisabledIcon.svg';
import { ReactComponent as GiftIcon } from '../../../assets/friends/GiftIcon.svg';

import styles from './RewardButton.module.scss';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { claimRewards } from '../../../api/auth';
import { fetchAccount } from '../../../store/reducers/accountSlice';
import { handleErrors } from '../../../core/helpers';
import useFormattedAsset, { getFormattedAmount } from '../../../hooks/useFormattedAsset';
import ButtonLoader from '../../common/loaders/buttonLoader';
import { toast } from 'react-toastify';
import { useReplenishTimer } from '../../../hooks/useReplenishTimer';
import { useTranslation } from 'react-i18next';
import { AssetType } from '../../../types/types';

const RewardButton = () => {
  const rewards = useAppSelector(state => state.account.account.rewards);
  const defaultDecimals = useAppSelector(state => state.settings.settings.networkSettings.decimals);
  const lastClaimRewards = useAppSelector(state => state.account.account.lastClaimRewards);
  const claimRewardsTime = useAppSelector(state => state.settings.settings.claimRewards.repeat) * 1000;
  const replenishTimer = useReplenishTimer(lastClaimRewards, claimRewardsTime);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();
  const formattedAsset = useFormattedAsset(null, rewards);
  const { t } = useTranslation();
  const rightIcon = useMemo(() => {
    if (isLoading) {
      return <ButtonLoader />;
    }
    if (rewards > 0 && !replenishTimer) {
      return <CoinsIcon />;
    }
    return <CoinsDisabledIcon />;
  }, [rewards, isLoading, replenishTimer]);

  const iconDisabled = useMemo(() => {
    if (isLoading || !rewards || !!replenishTimer) {
      return true;
    }
    return false;
  }, [rewards, isLoading, replenishTimer]);

  const handleClick = useCallback(async () => {
    try {
      setIsLoading(true);
      const rewards = await claimRewards();
      const balanceAsset = rewards.assets.find((item) => item.type === AssetType.Balance);
      const formattedRewards = getFormattedAmount(null, balanceAsset?.amount || 0, defaultDecimals);
      dispatch(fetchAccount());
      toast(t('toasts.defaultReplenishText', { replenishAmount: `${formattedRewards} ${formattedAsset.name}` }));
    } catch (err: any) {
      handleErrors(err);
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, formattedAsset.name, defaultDecimals, t]);

  return (
    <div className={styles.task_item__wrapper}>
      <div className={styles.task_item}>
        <div style={{ maxWidth: '56px' }}>
          <LeftSideIcon type='big' icon={<GiftIcon />} />
        </div>
        <div className={`${styles.task_info__wrapper} flex-break-word`}>
          <div className={styles.task_main_info__wrapper}>
            <Typography variant='body1' color='primary'>{t('pages.friends.sections.extraReward.title')}</Typography>
            <Typography variant='subheadline2' color='white'>{formattedAsset.amount} {formattedAsset.name}</Typography>
          </div>
          {replenishTimer && <Typography variant='caption1' color='secondary'>{t('pages.friends.sections.extraReward.secondTitle', { replenishTimer })}</Typography>}
        </div>
        <div style={{ maxWidth: '56px' }}>
          <IconButton disabled={iconDisabled} onClick={handleClick}>{rightIcon}</IconButton>
        </div>
      </div>
    </div>
  );
}

export default RewardButton;
