import React from 'react';

import styles from './Button.module.scss';

interface IProps {
  children?: React.ReactNode,
  disabled?: boolean,
  endIcon?: React.ReactNode,
  startIcon?: React.ReactNode,
  style?: object,
  onClick?: () => void,
}

const Button = ({ children, startIcon, endIcon, disabled, style, onClick }: IProps) => {
  return (
    <button onClick={onClick} style={style} className={`${styles.button} ${disabled ? styles.button__disabled : ''}`}>
      {startIcon}
      {children}
      {endIcon}
    </button>
  );
}

export default Button;
