import { useCallback, useMemo, useState } from 'react';
import useFormattedAsset, { getFormattedAmount } from '../../../hooks/useFormattedAsset';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';

import styles from './TreeHeader.module.scss';
import Typography from '../../common/typography';
import { replenish } from '../../../api/auth';
import { toast } from 'react-toastify';
import { fetchAccount } from '../../../store/reducers/accountSlice';
import { handleErrors } from '../../../core/helpers';

import { ReactComponent as TreeScoreIcon } from '../../../assets/friends/TreeScoreIcon.svg';
import { ReactComponent as WaterIcon } from '../../../assets/WaterIcon.svg';
// import { ReactComponent as WaterIcon } from '../../../assets/themeIcons/halloween/tree/HalloweenWaterIcon.svg';

import { useReplenishTimer } from '../../../hooks/useReplenishTimer';

import { useTranslation } from "react-i18next";

enum AssetType {
  Balance = 'Balance',
  Score = 'Score',
}

const TreeHeader = () => {
  const lastReplenish = useAppSelector(state => state.account.account.lastReplenish);
  const repeatReplenish = useAppSelector(state => state.settings.settings.replenish.repeat) * 1000;
  const defaultDecimals = useAppSelector(state => state.settings.settings.networkSettings.decimals);
  const score = useAppSelector(state => state.account.account.score);
  const replenishTimer = useReplenishTimer(lastReplenish, repeatReplenish);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const assets = useAppSelector(state => state.settings.settings.replenish.assets);
  const settingsAssets = useAppSelector(state => state.settings.settings.assets);

  const { t } = useTranslation();
  const settingsAssetsMap = useMemo(() => {
    return new Map(settingsAssets.map((item) => [item.type, item]));
  }, [settingsAssets]);
  const scoreAsset = useMemo(() => assets.find((item) => settingsAssetsMap.get(AssetType.Score)?.assetId === item.assetId), [assets, settingsAssetsMap]);
  const balanceAsset = useMemo(() => assets.find((item) => settingsAssetsMap.get(AssetType.Balance)?.assetId === item.assetId), [assets, settingsAssetsMap]);

  const formattedScoreAsset = useFormattedAsset(scoreAsset?.assetId || null, score);

  const dispatch = useAppDispatch();

  const formattedAsset = useFormattedAsset(null, balanceAsset?.amount || 0);

  const handleReplenish = useCallback(async () => {
    try {
      setIsLoading(true);
      await replenish();
      const formattedAmount = getFormattedAmount(null, balanceAsset?.amount || 0, defaultDecimals);
      toast(t('toasts.defaultReplenishText', { replenishAmount: `${formattedAmount} ${formattedAsset.name}` }));
      await dispatch(fetchAccount());
    } catch (err: any) {
      handleErrors(err);
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, formattedAsset.name, defaultDecimals, t, balanceAsset]);

  return (
    <div className={styles.container__wrapper}>
      <div className={styles.center_info_container}>
        <Typography variant='title2' color='white'>{t('pages.tree.sections.header.title')}</Typography>
        <Typography variant='title1' color='white'>{formattedAsset.amount} {formattedAsset.name}</Typography>
      </div>
      <div className={styles.side_info_container__left}>
        <Typography variant='caption1' color='white'>{t('pages.tree.sections.header.remainingTime')}</Typography>
        <Typography variant='body1' color='white'>{replenishTimer || '00:00:00'}</Typography>
      </div>
      <div className={styles.container_box_fill}></div>
      <div
        onClick={handleReplenish}
        className={styles.replenish_button_container}
        style={replenishTimer || isLoading ? { pointerEvents: 'none' } : {}}
      >
        {!replenishTimer && !isLoading && <WaterIcon className='importantSvg' style={{ marginLeft: '1.25px' }} />}
      </div>
      <div className={styles.side_info_container__right}>
        <Typography variant='caption1' color='white'>{t('pages.tree.sections.header.overallScore')}</Typography>
        <div className={styles.tree_score__wrapper}>
          <TreeScoreIcon />
          <Typography variant='body1' color='white'>{formattedScoreAsset.amount}</Typography>
        </div>
      </div>
    </div>
  );
}

export default TreeHeader;
