import React from 'react';

interface IProps {
  children: React.ReactElement,
  style?: object,
}

const PageContentWrapper = ({ children, style }: IProps) => {
  return (
    <div className='page-content-wrapper' style={style}>
      {children}
    </div>
  );
}

export default PageContentWrapper;
