import React from 'react';
import Typography from '../typography';

import { ReactComponent as CloseIcon } from '../../../assets/common/CloseIcon.svg';

import styles from './Dialog.module.scss'

interface IProps {
  title: string,
  onClose?: () => void,
  children: React.ReactNode,
}

const Dialog = ({ title, onClose, children }: IProps) => {
  return (
    <div className={styles.dialog__wrapper}>
      {onClose && <div className={styles.dialog__backdrop} onClick={onClose}></div>}
      <div className={styles.dialog}>
        <div className={styles.dialog__header}>
          <Typography variant='title3' color='white'>{title}</Typography>
          {onClose && <CloseIcon onClick={onClose} style={{ cursor: 'pointer' }} />}
        </div>
        <div className={styles.dialog__body}>
          {children}
        </div>
      </div>
    </div>
  );
}

export default Dialog;