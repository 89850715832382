import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { processTask } from '../../../api/auth';
import { handleErrors } from '../../../core/helpers';
import useFormattedAsset from '../../../hooks/useFormattedAsset';
import { IAddHomeScreenShortcutTaskItem, IProcessTask, } from '../../../types/types';
import TaskItem from '../taskItem';

import { ReactComponent as ShortcutIcon } from '../../../assets/tasks/ShortcutIcon.svg';
import { useTranslation } from 'react-i18next';

const tg = window.Telegram?.WebApp;

interface IProps {
  task: IAddHomeScreenShortcutTaskItem,
  onClick: (task: IProcessTask) => void,
}

enum shortcutTypes {
  Unsupported = 'unsupported',
  Unknown = 'unknown',
  Added = 'added',
  Missed = 'missed'
}

const TaskAddHomeScreenShortcutItem = ({ task, onClick }: IProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const formattedAsset = useFormattedAsset(null, task.reward);

  const { t } = useTranslation();

  const handleProcessTask = useCallback(async () => {
    try {
      setIsLoading(true);
      const res = await processTask(task.id);
      if (res.status === 'RewardClaimed') {
        toast(t('toasts.defaultReplenishText', { replenishAmount: `${formattedAsset.amount} ${formattedAsset.name}` }));
      }
      onClick(res);
    } catch (err: any) {
      handleErrors(err);
    } finally {
      setIsLoading(false);
    }
  }, [onClick, task.id, formattedAsset.amount, formattedAsset.name, t]);

  const processShortcutStatus = useCallback(async (type: 'start' | 'verify') => {
    await tg.checkHomeScreenStatus(async (status: shortcutTypes) => {
      if (status === shortcutTypes.Unsupported) {
        return toast.error(t('pages.tasks.sections.promo.toasts.errors.featureNotSupported'));
      }
      if (status === shortcutTypes.Unknown && type === 'start') {
        await handleProcessTask();
        return tg.addToHomeScreen();
      }
      if ((status === shortcutTypes.Added || status === shortcutTypes.Unknown) && type === 'verify') {
        return await handleProcessTask();
      }
      return tg.addToHomeScreen();
    });
  }, [t, handleProcessTask]);

  const handleClick = useCallback(async () => {
    setTimeout(async () => {
      try {
        if (task.operation === 'Start') {
          await processShortcutStatus('start');
        }
        if (task.operation === 'Verify') {
          await processShortcutStatus('verify');
        }
      } catch (err: any) {
        handleErrors(err);
      }
    })

  }, [processShortcutStatus, task.operation]);

  return (
    <TaskItem task={task} isLoading={isLoading} status={task.status} leftIcon={<ShortcutIcon />} description={`+ ${formattedAsset.amount} ${formattedAsset.name}`} onClick={handleClick} />
  );
}

export default TaskAddHomeScreenShortcutItem;
