import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './locales/en/translation.json';
import ru from './locales/ru/translation.json';

const tg = window.Telegram?.WebApp;

const lng = tg?.initDataUnsafe?.user?.language_code;

i18next.use(initReactI18next).init({
  lng,
  fallbackLng: 'en',
  defaultNS: 'translation',
  returnNull: false,
  resources: {
    en: {
      translation: en,
    },
    ru: {
      translation: ru,
    },
  },

});