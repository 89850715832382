import { Outlet, useLocation } from 'react-router-dom';

import styles from './Main.module.scss';
import Footer from '../../components/footer';
import TreePage from '../tree';

const Main = () => {
  const location = useLocation();
  const { pathname } = location;
  return (
    <div className={styles.main}>
      <div id='tree' style={{ visibility: pathname === '/' ? 'visible' : 'hidden', height: pathname === '/' ? 'auto' : '0' }}>
        <TreePage />
      </div>
      <Outlet />
      <Footer />
    </div>
  );
}

export default Main;
