import { useCallback, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

import PumpkinImage from '../../../assets/pumpkin.png';
import FlowerImage from '../../../assets/tasks/findObjectIcons/FoundFlowerImage.png';

import styles from './DialogFindObject.module.scss';
import { useTranslation } from 'react-i18next';
import useFormattedAsset from '../../../hooks/useFormattedAsset';
import Dialog from '../../common/dialog';
import Button from '../../common/button';
import Typography from '../../common/typography';
import ButtonLoader from '../../common/loaders/buttonLoader';
import { FindObjectTaskAssets, IFindObjectTask } from '../../../types/types';
import { processTask } from '../../../api/auth';
import { handleErrors } from '../../../core/helpers';
import { useAppDispatch } from '../../../store/hooks';
import { fetchUserTasks } from '../../../store/reducers/accountSlice';

interface IProps {
  handleClose: (asset?: FindObjectTaskAssets) => void,
  task: IFindObjectTask,
}

const DialogFindObject = ({ handleClose, task }: IProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const formattedAsset = useFormattedAsset(null, task?.reward || 0);
  const dispatch = useAppDispatch();

  const dialogImage = useMemo(() => {
    switch (task.settings.data.asset) {
      case FindObjectTaskAssets.Flower:
        return FlowerImage;
      case FindObjectTaskAssets.Candy:
        return PumpkinImage;
      default:
        return FlowerImage;
    }
  }, [task.settings.data.asset]);

  const handleClick = useCallback(async () => {
    try {
      if (!task) {
        return handleClose();
      }
      setIsLoading(true);
      await processTask(task?.id);
      await dispatch(fetchUserTasks());
      toast(t('toasts.defaultReplenishText', { replenishAmount: `${formattedAsset.amount} ${formattedAsset.name}` }));
      handleClose(task.settings.data.asset);
    } catch (err: any) {
      handleErrors(err);
    } finally {
      setIsLoading(false);
    }
  }, [task, formattedAsset.amount, formattedAsset.name, t, handleClose, dispatch]);

  return (
    <Dialog title={t(`pages.tasks.sections.season.assets.${task.settings.data.asset.toLowerCase()}.header`)}>
      <div className={styles.dialog__content}>
        <img src={dialogImage} alt='img' height={277}></img>
        <div className={styles.info__wrapper}>
          <Typography variant="subheadline1" color="primary">
            {t(`pages.tasks.sections.season.assets.${task.settings.data.asset.toLowerCase()}.title`)}
          </Typography>
          <Typography variant="title2" color="white" style={{ textAlign: 'center' }}>
            {t(`pages.tasks.sections.season.assets.${task.settings.data.asset.toLowerCase()}.secondTitle`)}
          </Typography>
          <Typography variant="subheadline2" color="secondary" style={{ display: 'inline-block', wordBreak: 'break-word' }}>
            {t(`pages.tasks.sections.season.assets.${task.settings.data.asset.toLowerCase()}.description`)}
          </Typography>
          <Button
            onClick={handleClick}
            disabled={isLoading}
            endIcon={isLoading ? <ButtonLoader /> : null}
          >
            {t(`pages.tasks.sections.season.assets.${task.settings.data.asset.toLowerCase()}.buttonText`)}
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

export default DialogFindObject;
