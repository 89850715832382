import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import DialogTransactionForm from '../../components/wallet/dialogTransactionForm';
import TransactionListItem from '../../components/wallet/transaction-list-item';
import { ITransaction } from '../../types/types';
import useFormattedAsset from '../../hooks/useFormattedAsset';
import DialogShareQr from '../../components/wallet/dialogShareQr/DialogShareQr';
import { useAppDispatch, useAppSelector } from '../../store/hooks';

import Typography from '../../components/common/typography';
import Button from '../../components/common/button';
import IconButton from '../../components/common/iconButton';
import CopyButton from '../../components/common/copyButton';

import styles from './Wallet.module.scss';

import { ReactComponent as QRIcon } from '../../assets/common/QRIcon.svg';
import List from '../../components/tasks/list';
import Divider from '../../components/common/divider';
import { fetchBalance, fetchTransactions } from '../../store/reducers/walletSlice';
import PageWrapper from '../../components/common/pageWrapper';
import PageContentWrapper from '../../components/common/pageContentWrapper';
import { useTranslation } from 'react-i18next';

const Wallet = () => {
  const address = useAppSelector(state => state.account.account.walletAddress);
  const balance = useAppSelector(state => state.wallet.balance);
  const transactions = useAppSelector(state => state.wallet.transactions);
  const [isTransactionFormOpen, setTransactionFormOpen] = useState<boolean>(false);
  const [isShareQrOpen, setShareQrOpen] = useState<boolean>(false);
  const minimumReferrals = useAppSelector(state => state.settings.settings.wallet.minimumReferrals);
  const referralsAmount = useAppSelector(state => state.account.referals.total);
  const username = useAppSelector(state => state.account.account.username);
  const explorerUrl = useAppSelector(state => state.settings.explorerUrl);
  const formattedAsset = useFormattedAsset(null, balance ? balance.stable - balance.outgoing : 0);

  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const fetchAddressBalance = useCallback(async () => {
    if (!address) {
      return;
    }
    dispatch(fetchBalance(address));
  }, [address, dispatch]);

  const fetchAddressTransactions = useCallback(async () => {
    if (!address) {
      return;
    }
    dispatch(fetchTransactions(address));
  }, [address, dispatch]);

  const handleTransactionFormOpen = () => {
    setTransactionFormOpen(true);
  };

  const handleTransactionFormClose = () => {
    setTransactionFormOpen(false);
  };

  const handleShareQrOpen = () => {
    setShareQrOpen(true);
  };

  const handleShareQrClose = () => {
    setShareQrOpen(false);
  };

  const handleTransactionFormSend = () => {
    setTransactionFormOpen(false);
    fetchAddressBalance();
    fetchAddressTransactions();
  };

  const formattedUsername = useMemo(() => {
    if (username.length > 15) {
      return `@${username.slice(0, 15)}...`
    }
    return `@${username}`
  }, [username]);

  const setup = useCallback(() => {
    fetchAddressBalance();
    fetchAddressTransactions();
  }, [fetchAddressBalance, fetchAddressTransactions]);

  useEffect(() => {
    setup();
  }, [setup]);

  return (
    <PageWrapper>
      <PageContentWrapper>
        <>
          <div className={styles.wallet__balance__container}>
            <div className={styles.wallet__balance__header}>
              <Typography variant='subheadline2' color='black'>{t('pages.wallet.sections.balance.title')}</Typography>
              <Typography variant='subheadline2' color='black'>{formattedUsername}</Typography>
            </div>
            <div className={styles.wallet__balance}>
              <Typography variant='title-large' color='black'>{formattedAsset.amount} {formattedAsset.name}</Typography>
            </div>
          </div>

          <div className={styles.wallet__wrapper}>
            <Typography style={{ marginLeft: '16px' }} variant='subheadline1' color='white'>{t('pages.wallet.sections.wallet.title')}</Typography>
            <div className={styles.wallet_address__container}>
              <div className={styles.wallet_address}>
                <Typography variant='subheadline2' color='white'>{address}</Typography>
              </div>
              <div className={styles.wallet_address__buttons}>
                <CopyButton data={address} />
                <IconButton onClick={handleShareQrOpen}><QRIcon /></IconButton>
              </div>
            </div>
            {referralsAmount < minimumReferrals && <Typography style={{ marginLeft: '16px' }} variant='caption1' color='secondary'>{t('pages.wallet.buttons.send.helperText', { minimumReferrals })}</Typography>}
            {balance && <Button disabled={referralsAmount < minimumReferrals} onClick={handleTransactionFormOpen}>{t('pages.wallet.buttons.send.title')}</Button>}
          </div>
          <List
            items={transactions?.data || []}
            title={t('pages.wallet.sections.transactions.title')}
            emptyListMessage={t('emptyListText')}
            renderItem={(item: ITransaction, index: number) =>
              <Fragment key={item.hash}>
                <TransactionListItem address={address} transaction={item} />
                {index !== (transactions?.data?.length || 0) - 1 && <Divider />}
              </Fragment>}
          />
          {balance && isTransactionFormOpen && <DialogTransactionForm handleClose={handleTransactionFormClose} balance={balance.stable} handleTransactionFormSend={handleTransactionFormSend} />}
          {address && isShareQrOpen && <DialogShareQr handleClose={handleShareQrClose} address={address} />}
          <a style={{ width: '100%', textDecoration: 'none' }} href={`${explorerUrl}/address/${address}`} rel="noreferrer" target="_blank">
            <Button>
              {t('pages.wallet.buttons.walletInfo.title')}
            </Button>
          </a>
        </>
      </PageContentWrapper>
    </PageWrapper>
  );
}

export default Wallet;
