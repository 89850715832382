import { useCallback, useEffect } from 'react';
import { getToken } from '../../api/auth';
import { handleErrors } from '../../core/helpers';
import storage from '../../core/storage';
import { useAppDispatch } from '../hooks';
import { fetchAccount, fetchReferrals, fetchUserTasks, setCredentials } from '../reducers/accountSlice';
import { fetchSettings, setConfiguration } from '../reducers/settingsSlice';
import { fetchBalance, fetchTransactions } from '../reducers/walletSlice';
import { getWallet, isCredentialsEncrypted } from '../../core/wallet';
import { fetchRanks, fetchStatistics } from '../reducers/statisticsSlice';
import { IAccount, IConfiguration, RankType, TreeType } from '../../types/types';
import { getRandomSeed } from '../../core/tree';
import { fetchTranslations } from '../reducers/translationsSlice';

const tg = window.Telegram.WebApp;

export interface SetupStoreProps {
  onSetup: () => void;
  onError?: (err: any) => void;
}

export default function SetupStore({ onSetup, onError }: SetupStoreProps) {
  const dispatch = useAppDispatch();

  const setupAccount = useCallback(async (address: string) => {
    dispatch(fetchTranslations());
    dispatch(fetchBalance(address));
    dispatch(fetchTransactions(address));
    dispatch(fetchReferrals());
    dispatch(fetchUserTasks());
    dispatch(fetchRanks(RankType.Score));
    dispatch(fetchStatistics());
  }, [dispatch]);

  const loadConfiguration = useCallback(async () => {
    let configurationString = await storage.getItem(process.env.REACT_APP_CLOUD_CONFIGURATION_KEY as string);
    let configuration: IConfiguration;
    let isUpdated = false;
    if (!configurationString) {
      const credentials = await storage.getItem(process.env.REACT_APP_CLOUD_STORAGE_KEY as string);
      if (credentials) {
        let [type, seed] = await Promise.all([
          storage.getItem(process.env.REACT_APP_TREE_TYPE_KEY as string),
          storage.getItem(process.env.REACT_APP_TREE_SEED_KEY as string),
        ]);
        configuration = { credentials, type, seed };
        isUpdated = true;
      } else {
        configuration = {} as IConfiguration;
      }
    } else {
      configuration = JSON.parse(configurationString);
    }
    if (!configuration.type || !TreeType[configuration.type as TreeType]) {
      configuration.type = TreeType.Ash;
      isUpdated = true;
    }
    if (!configuration.seed || isNaN(Number(configuration.seed))) {
      const randomSeed = getRandomSeed();
      configuration.seed = randomSeed.toString();
      isUpdated = true;
    }
    if (!configuration.credentials) {
      const wallet = getWallet();
      const phrase = wallet.getMnemonicPhrase();
      configuration.credentials = phrase;
      isUpdated = true;
    }
    if (isUpdated) {
      await storage.setItem(process.env.REACT_APP_CLOUD_CONFIGURATION_KEY as string, JSON.stringify(configuration));
    }
    return configuration;
  }, []);

  const setup = useCallback(async () => {
    try {
      const [configuration] = await Promise.all([
        loadConfiguration(),
        dispatch(fetchSettings()),
      ]);
      const { credentials, type, seed } = configuration;
      let walletAddress;
      if (!isCredentialsEncrypted(credentials)) {
        const wallet = getWallet(credentials);
        walletAddress = wallet.getAddress();
      }
      dispatch(setCredentials(credentials));
      dispatch(setConfiguration(configuration));

      await getToken({ grantType: 'Signature', payload: tg.initData, walletAddress, treeSettings: { type, seed: Number(seed) } });

      const account = await dispatch(fetchAccount());

      setupAccount((account.payload as IAccount).walletAddress);
    } catch (err: any) {
      handleErrors(err);
      onError && onError(err);
    } finally {
      onSetup();
    }
  }, [dispatch, onError, onSetup, setupAccount, loadConfiguration]);

  useEffect(() => {
    setup();
  }, [setup]);

  return null;
}