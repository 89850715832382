const encryptor = new window.Encryptor();

export async function encrypt(data: string, password: string) {
  try {
    return await encryptor.encrypt(data, password);
  } catch (err) {
    // throw err;
    return null;
  } 
  
}

export async function decrypt(hash: any, password: string) {
  if(!hash) {
    return null;
  }
  try {
    return await encryptor.decrypt(hash, password);
  } catch (err) {
    // throw err;
    return null;
  } 
}