import React from 'react';

import styles from './SceneSettingsList.module.scss';

interface IProps<T> {
  items: T[],
  renderItem: (item: T, index: number) => React.ReactNode,
}

function SceneSettingsList<T>({ items, renderItem }: IProps<T>) {
  return (
    <div className={styles.list}> {items.map((item, index) => renderItem(item, index))} </div>
  );
}

export default SceneSettingsList;
