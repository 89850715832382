import ash from './ash.json';
import ashSmall from './ash_small.json';
import ashMedium from './ash_medium.json';
import ashLarge from './ash_large.json';
import aspenSmall from './aspen_small.json';
import aspenMedium from './aspen_medium.json';
import aspenLarge from './aspen_large.json';
import bush1 from './bush_1.json';
import bush2 from './bush_2.json';
import bush3 from './bush_3.json';
import oakSmall from './oak_small.json';
import oakMedium from './oak_medium.json';
import oakLarge from './oak_large.json';
import pine from './pine.json';
import pineSmall from './pine_small.json';
import pineMedium from './pine_medium.json';
import pineLarge from './pine_large.json';
import { TreeOptions } from '@dkostenevich/ez-tree';

const cdnUrl = process.env.REACT_APP_CDN_URL;

const TreePresetHash: { [key: string]: TreeOptions } = {};

const DefaultTreePreset: { [key: string]: TreeOptions } = {
  'Ash': ash as TreeOptions,
  'Ash Small': ashSmall as TreeOptions,
  'Ash Medium': ashMedium as TreeOptions,
  'Ash Large': ashLarge as TreeOptions,
  'Aspen': aspenMedium as TreeOptions,
  'Aspen Small': aspenSmall as TreeOptions,
  'Aspen Medium': aspenMedium as TreeOptions,
  'Aspen Large': aspenLarge as TreeOptions,
  'Bush 1': bush1 as TreeOptions,
  'Bush 2': bush2 as TreeOptions,
  'Bush 3': bush3 as TreeOptions,
  'Oak': oakMedium as TreeOptions,
  'Oak Small': oakSmall as TreeOptions,
  'Oak Medium': oakMedium as TreeOptions,
  'Oak Large': oakLarge as TreeOptions,
  'Pine': pine as TreeOptions,
  'Pine Small': pineSmall as TreeOptions,
  'Pine Medium': pineMedium as TreeOptions,
  'Pine Large': pineLarge as TreeOptions,
};

export const getTreePreset = (key: string) => {
  if (TreePresetHash[key]) {
    return TreePresetHash[key];
  }
  const preset = DefaultTreePreset[key];
  const barkAssets = preset.bark.assets;
  const updatedPreset = {
    ...preset,
    bark: {
      ...preset.bark,
      assets: {
        ao: `${cdnUrl}${barkAssets.ao}`,
        color: `${cdnUrl}${barkAssets.color}`,
        normal: `${cdnUrl}${barkAssets.normal}`,
        roughness: `${cdnUrl}${barkAssets.roughness}`,
      }
    },
    leaves: {
      ...preset.leaves,
      asset: `${cdnUrl}${preset.leaves.asset}`
    }
  };
  TreePresetHash[key] = updatedPreset as TreeOptions;
  return updatedPreset as TreeOptions;
};

/**
 * @param {string} name The name of the preset to load
 * @returns {TreeOptions}
 */