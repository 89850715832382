import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { IFetchedRanks, IRank, IStatistics, RankType } from '../../types/types'
import { handleErrors } from '../../core/helpers'
import { getRanks, getStatistics } from '../../api/auth'

export const fetchStatistics = createAsyncThunk<IStatistics, undefined, { rejectValue: string }>(
  'statistics/fetchStatistics',
  async (_, { rejectWithValue }) => {
    try {
      return await getStatistics();
    } catch (err: any) {
      handleErrors(err);
      return rejectWithValue(err.message);
    }
  }
);

export const fetchRanks = createAsyncThunk<IFetchedRanks, RankType, { rejectValue: string }>(
  'statistics/fetchRanks',
  async (type, { rejectWithValue }) => {
    try {
      return await getRanks(type);
    } catch (err: any) {
      handleErrors(err);
      return rejectWithValue(err.message);
    }
  }
);

interface StatisticsState {
  statistics: IStatistics,
  ranks: IFetchedRanks;
}

const initialState: StatisticsState = {
  statistics: {} as IStatistics,
  ranks: {
    items: [],
    ownRank: {} as IRank
  } as IFetchedRanks,
}

export const statisticsSlice = createSlice({
  name: 'statistics',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchStatistics.fulfilled, (state, action) => {
      state.statistics = action.payload;
    }).addCase(fetchRanks.fulfilled, (state, action) => {
      state.ranks = action.payload;
    })

  }
})

export default statisticsSlice.reducer;