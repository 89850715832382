import React from 'react';
import Typography from '../typography';

import styles from './LeftSideIcon.module.scss';

const iconStyles = {
  regular: {
    minWidth: 40,
    minHeight: 40
  },
  big: {
    minWidth: 56,
    minHeight: 56
  }
}

interface IProps {
  type?: 'regular' | 'big',
  icon: React.ReactNode,
  premiumOnly?: boolean,
  subIconContent?: any,
}

const LeftSideIcon = ({ type = 'regular', icon, subIconContent, premiumOnly }: IProps) => {
  return (
    <div style={iconStyles[type]} className={styles.icon_container}>
      {icon}
      {subIconContent && <div className={styles.subicon_container}>
        <Typography variant='caption1' color='black'>{subIconContent}</Typography>
      </div>}
      {premiumOnly && <div className={styles.premium}>
        <Typography variant='caption2' color='black'>premium</Typography>
      </div>}
    </div>
  );
}

export default LeftSideIcon;
