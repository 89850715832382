import React, { useCallback, useMemo, useState } from 'react';
import useFormattedAsset from '../../../hooks/useFormattedAsset';
import { useReplenishTimer } from '../../../hooks/useReplenishTimer';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import IconButton from '../../common/iconButton';
import LeftSideIcon from '../../common/leftSideIcon';
import Typography from '../../common/typography';
import ButtonLoader from '../../common/loaders/buttonLoader';

import styles from './AdvertisementTask.module.scss';

import { ReactComponent as AdIcon } from '../../../assets/tasks/AdIcon.svg';
import { ReactComponent as RocketIcon } from '../../../assets/tasks/RocketIcon.svg';
import { ReactComponent as RocketIconDisabled } from '../../../assets/tasks/RocketIconDisabled.svg';
import { handleErrors } from '../../../core/helpers';
import { toast } from 'react-toastify';
import { watchAdvertisement } from '../../../api/auth';
import { fetchAccount } from '../../../store/reducers/accountSlice';
import { useTranslation } from 'react-i18next';

const AdvertisementTask = () => {
  const lastAdView = useAppSelector(state => state.account.account.lastAdView);
  const watchAdTime = useAppSelector(state => state.settings.settings.ads.repeat) * 1000;
  const ads = useAppSelector(state => state.settings.settings.ads);
  const watchAdTimer = useReplenishTimer(lastAdView, watchAdTime);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();
  const formattedAsset = useFormattedAsset(ads.rewardAssets[0].assetId, ads.rewardAssets[0].amount);

  const { t } = useTranslation();

  const iconDisabled = useMemo(() => {
    if (isLoading || !!watchAdTimer) {
      return true;
    }
    return false;
  }, [isLoading, watchAdTimer]);

  const rightIcon = useMemo(() => {
    if (isLoading) {
      return <ButtonLoader />;
    }
    if (iconDisabled) {
      return <RocketIconDisabled />;
    }
    return <RocketIcon />;
  }, [isLoading, iconDisabled]);

  const showAd = useCallback(async () => {
    try {
      const AdController = window.Adsgram.init({ blockId: String(ads.blockId), debug: ads.debug });
      await AdController.show();
      toast(t('toasts.defaultReplenishText', { replenishAmount: `${formattedAsset.amount} ${formattedAsset.name}` }));
    } catch (err: any) {
      if (err.error) {
        toast.error(t('pages.tasks.advertisement.toasts.errors.watchVideoError'));
      }
      throw err;
    }
  }, [ads.blockId, ads.debug, formattedAsset.amount, formattedAsset.name, t]);

  const handleClick = useCallback(async () => {
    try {
      setIsLoading(true);
      await watchAdvertisement();
      await dispatch(fetchAccount());
      await showAd();
    } catch (err: any) {
      handleErrors(err);
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, showAd]);

  return (
    <div className={styles.list__wrapper}>
      {/* <div className={styles.title__container}>
        <Typography style={{ marginLeft: '16px' }} variant='subheadline1' color='white'>Promo task</Typography>
      </div> */}
      <div className={styles.task_item__wrapper}>
        <div className={styles.task_item}>
          <div style={{ maxWidth: '56px' }}>
            <LeftSideIcon icon={<AdIcon />} />
          </div>
          <div className={`${styles.task_info__wrapper} flex-break-word`}>
            <div className={styles.task_main_info__wrapper}>
              <Typography variant='body1' color='primary'>{t('pages.tasks.sections.advertisement.title')}</Typography>
              <Typography variant='subheadline2' color='white'>+ {formattedAsset.amount} {formattedAsset.name}</Typography>
            </div>
            {watchAdTimer && <Typography variant='caption1' color='secondary'>{t('pages.tasks.sections.advertisement.secondTitle', { watchAdTimer })}</Typography>}
          </div>
          <div style={{ maxWidth: '56px' }}>
            <IconButton disabled={iconDisabled} onClick={handleClick}>{rightIcon}</IconButton>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdvertisementTask;
