import useFormattedAsset from '../../../hooks/useFormattedAsset';
import { useReplenishTimer } from '../../../hooks/useReplenishTimer';
import { useAppSelector } from '../../../store/hooks';
import LeftSideIcon from '../../common/leftSideIcon';
import Typography from '../../common/typography';
import { useTranslation } from 'react-i18next';
import { IFindOjbectTaskItem, FindObjectTaskAssets } from '../../../types/types';
import { useMemo } from 'react';
import { useServerTranslate } from '../../../hooks/useServerTranslate';

import styles from './FindObjectTask.module.scss';

import { ReactComponent as WalletIcon } from '../../../assets/themeIcons/halloween/footer/HalloweenWalletIcon.svg';
import { ReactComponent as FlowerIcon } from '../../../assets/tasks/findObjectIcons/FlowerIcon.svg';

const FindObjectTask = ({ task }: { task: IFindOjbectTaskItem }) => {
  const translatedTitle = useServerTranslate(task.titleTranslationKey, task.title);
  const taskIcon = useMemo(() => {
    switch (task.settings.data.asset) {
      case FindObjectTaskAssets.Candy:
        return <WalletIcon className='importantSvg' style={{ fill: "white" }} />
      case FindObjectTaskAssets.Flower:
        return <FlowerIcon />
    }
  }, [task.settings.data.asset]);
  const userTasks = useAppSelector(state => state.account.userTasks);
  const lastCandyClaimed = useMemo(() => {
    return userTasks.find((item) => item.taskId === task.id && item.status === 'RewardClaimed')?.updatedAt || '';
  }, [userTasks, task.id]);
  const repetitionTime = task.repetitionTime * 1000 * 60;
  const nextClaimTimer = useReplenishTimer(lastCandyClaimed, repetitionTime);
  const formattedAsset = useFormattedAsset(null, task.reward);

  const { t } = useTranslation();

  return (
    <div className={styles.list__wrapper}>
      <div className={styles.task_item__wrapper}>
        <div className={styles.task_item}>
          <div style={{ maxWidth: '56px' }}>
            <LeftSideIcon icon={taskIcon} />
          </div>
          <div className={`${styles.task_info__wrapper} flex-break-word`}>
            <div className={styles.task_main_info__wrapper}>
              <Typography variant='body1' color='primary'>{translatedTitle}</Typography>
              <Typography variant='subheadline2' color='white'>+ {formattedAsset.amount} {formattedAsset.name}</Typography>
            </div>
            {nextClaimTimer && <Typography variant='caption1' color='secondary'>{t('nextFindObjectClaim', { nextClaimTimer })}</Typography>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default FindObjectTask;
