import MainLoader from '../../common/loaders/mainLoader';
import SocialsButton from '../../common/socialsButton';
import Typography from '../../common/typography';

import styles from './GlobalLoader.module.scss';

import { ReactComponent as InstagramSocialsIcon } from '../../../assets/socials/InstagramSocialsIcon.svg';
import { ReactComponent as TelegramSocialsIcon } from '../../../assets/socials/TelegramSocialsIcon.svg';
import { ReactComponent as TwitterSocialsIcon } from '../../../assets/socials/TwitterSocialsIcon.svg';
import { useTranslation } from 'react-i18next';

const GlobalLoader = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.loader__wrapper}>
      <div className={styles.loader__block__wrapper}>
        <div className={styles.loader__block}>
          <MainLoader />
          <Typography variant='subheadline1' color='black'>{t('loaders.global.loading')}</Typography>
        </div>
        <div className={styles.loader__block}>
          <Typography variant='title-large' color='black'>{t('loaders.global.title')}</Typography>
          <Typography variant='title-large' color='black'>{t('loaders.global.secondTitle')}</Typography>
        </div>
      </div>
      <div className={styles.loader_bottom_block__wrapper}>
        <Typography variant='title3' color='primary'>{t('loaders.global.description')}</Typography>
        <div className={styles.loader_socials}>
          <SocialsButton size={44}>
            <TwitterSocialsIcon className='importantSvg' />
          </SocialsButton>
          <SocialsButton size={44}>
            <TelegramSocialsIcon className='importantSvg' />
          </SocialsButton>
          <SocialsButton size={44}>
            <InstagramSocialsIcon className='importantSvg' />
          </SocialsButton>
        </div>
      </div>
    </div>
  );
}

export default GlobalLoader;
