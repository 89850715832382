import React from 'react';
import Typography from '../typography';

import styles from './CustomInput.module.scss';

interface IProps {
  type: string,
  value: any,
  title?: string,
  placeholder?: string,
  name?: string,
  icon?: React.ReactNode,
  error?: boolean,
  helperText?: string | null,
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

const CustomInput = ({ type, value, placeholder, title, name, icon, error, helperText, onChange, onKeyDown }: IProps) => {
  return (
    <div className={styles.input__wrapper}>
      {title && <Typography style={{ marginLeft: '16px' }} variant='subheadline1' color={error ? 'error' : 'secondary'}>{title}</Typography>}
      <div className={`${styles.input__container} ${error ? styles.input__container__error : ''}`}>
        <input className={styles.input} type={type} placeholder={placeholder} name={name} value={value} onKeyDown={onKeyDown} onChange={onChange}/>
        <div className={styles.icon__container}>{icon}</div>
      </div>
      {helperText && <Typography style={{ marginLeft: '16px' }} variant='subheadline2' color={error ? 'error' : 'secondary'}>{helperText}</Typography>}
    </div>
  );
}

export default CustomInput;
