import React, { useCallback, useMemo, } from 'react';

import { Pagination } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';
import StatisticsItem from '../statisticsItem';
import { RankType } from '../../../types/types';

interface IProps {
  onSwipe: (type: RankType) => void;
}

const RankSlider = ({ onSwipe }: IProps) => {
  const swipeTypes: RankType[] = useMemo(() => [RankType.Score, RankType.Referrals, RankType.ReplenishCount], []);
  const handleSwipe = useCallback((type: RankType) => {
    onSwipe(type);
  }, [onSwipe]);
  return (
    <Swiper
      modules={[Pagination]}
      spaceBetween={12}
      centeredSlides={true}
      loop={true}
      slidesPerView={1.3}
      pagination={{ clickable: true }}
    >
      {swipeTypes.map((item, index) => <SwiperSlide key={index}>
        {({ isActive }: { isActive: boolean }) =>
          <StatisticsItem key={index} type={item as RankType} isActive={isActive} onSwipe={handleSwipe} />}
      </SwiperSlide>)}
    </Swiper >
  );
}

export default RankSlider;
