import { useCallback, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { processTask } from '../../../api/auth';
import { handleErrors } from '../../../core/helpers';
import useFormattedAsset from '../../../hooks/useFormattedAsset';
import { IProcessTask, IReferralTaskItem } from '../../../types/types';

import { ReactComponent as UserIcon } from '../../../assets/tasks/UserIcon.svg';

import TaskItem from '../taskItem';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../store/hooks';

interface IProps {
  task: IReferralTaskItem,
  onClick: (task: IProcessTask) => void,
}

const TaskReferralItem = ({ task, onClick }: IProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const referralsAmount = useAppSelector(state => state.account.referals.total);
  const completeText = useMemo(() => {
    return `${referralsAmount} / ${task.settings.count}`;
  }, [referralsAmount, task.settings.count]);
  const formattedAsset = useFormattedAsset(null, task.reward);
  const handleClick = useCallback(async () => {
    try {
      setIsLoading(true);
      const res = await processTask(task.id);
      toast(t('toasts.defaultReplenishText', { replenishAmount: `${formattedAsset.amount} ${formattedAsset.name}` }));
      onClick(res);
    } catch (err: any) {
      handleErrors(err);
    } finally {
      setIsLoading(false);
    }
  }, [formattedAsset.amount, formattedAsset.name, onClick, task.id, t]);

  return (
    <TaskItem task={task} isLoading={isLoading} status={task.status} completeText={completeText} leftIcon={<UserIcon />} description={`+ ${formattedAsset.amount} ${formattedAsset.name}`} onClick={handleClick} />
  );
}

export default TaskReferralItem;
