import React, { useMemo } from 'react';
import IconButton from '../../common/iconButton';
import LeftSideIcon from '../../common/leftSideIcon';
import Typography from '../../common/typography';

import { ReactComponent as CompleteIcon } from '../../../assets/tasks/CompleteIcon.svg';
import { ReactComponent as CoinsIcon } from '../../../assets/tasks/CoinsIcon.svg';
import { ReactComponent as CoinsDisabledIcon } from '../../../assets/tasks/CoinsDisabledIcon.svg';
import { ReactComponent as RocketIcon } from '../../../assets/tasks/RocketIcon.svg';
import { ReactComponent as CheckIcon } from '../../../assets/tasks/CheckIcon.svg';
import { ReactComponent as TrophyIcon } from '../../../assets/tasks/TrophyIcon.svg';
import { ReactComponent as TelegramStarPremiumIcon } from '../../../assets/tasks/TelegramStarPremiumIcon.svg';

import styles from './TaskItem.module.scss';
import { useServerTranslate } from '../../../hooks/useServerTranslate';
import ButtonLoader from '../../common/loaders/buttonLoader';
import { useAppSelector } from '../../../store/hooks';
import { ITask } from '../../../types/types';
import { useReplenishTimer } from '../../../hooks/useReplenishTimer';
import { useTranslation } from 'react-i18next';
interface IProps {
  task: ITask,
  isLoading?: boolean,
  description: string,
  leftIcon: React.ReactNode,
  status: string,
  completeText?: string,
  disabled?: boolean,
  onClick: () => void,
}

const TaskItem = ({ task, status, isLoading, description, leftIcon, completeText, disabled, onClick }: IProps) => {
  const translatedTitle = useServerTranslate(task.titleTranslationKey, task.title);
  const userTask = useAppSelector(state => state.account.userTasks.find((item) => item.taskId === task.id));
  const lastClaimed = useMemo(() => status === 'RewardClaimed' ? userTask!.updatedAt || '' : '', [userTask, status]);
  const nextClaimTime = task.repetitionTime * 1000 * 60;
  const nextClaimTimer = useReplenishTimer(lastClaimed, nextClaimTime);
  const { t } = useTranslation();

  const isDisabled = useMemo(() => {
    const disabledByStatus = (task.type === 'Referral' || task.type === 'Progress') && status !== 'Completed';
    // const disabledByPremium = task.premiumOnly && !isPremium;
    return disabledByStatus || !!nextClaimTimer || isLoading || disabled;
  }, [disabled, isLoading, nextClaimTimer, status, task.type]);

  const rightIcon = useMemo(() => {
    if (isLoading) {
      return <ButtonLoader />;
    }
    if (task.type === 'Referral' || task.type === 'Progress') {
      switch (status) {
        case 'Completed':
          return <CoinsIcon />;
        case 'RewardClaimed':
          return null;
        default:
          return <CoinsDisabledIcon />
      }
    }
    switch (status) {
      case 'NotStarted':
        return <RocketIcon style={isDisabled ? { stroke: 'var(--basic--secondary)', fill: 'var(--basic--secondary)' } : {}} />;
      case 'Started':
        return <CheckIcon style={isDisabled ? { stroke: 'var(--basic--secondary)', fill: 'var(--basic--secondary)' } : {}} />;
      case 'Completed':
        return <CoinsIcon />;
      default:
        return null;
    }
  }, [status, task.type, isLoading, isDisabled,]);

  return (
    <div className={styles.task_item__wrapper}>
      <div className={styles.task_item}>
        <div style={{ maxWidth: '56px' }}>
          <LeftSideIcon icon={leftIcon} subIconContent={status === 'RewardClaimed' ? <CompleteIcon /> : null} premiumOnly={task.premiumOnly} />
        </div>
        <div className={`${styles.task_info__wrapper} flex-break-word`}>
          <Typography variant='body1' color='white'>{translatedTitle}{task.premiumOnly && <TelegramStarPremiumIcon style={{ marginLeft: '4px' }} />}</Typography>
          <Typography variant='subheadline2' color='white'>{description}</Typography>
          {completeText && <div className={styles.task_complete__wrapper}>
            <TrophyIcon />
            <Typography variant='caption1' color='secondary'>{completeText}</Typography>
          </div>}
          {nextClaimTimer && <Typography variant='caption1' color='secondary'>{t('nextAttempt', { nextClaimTimer })}</Typography>}
        </div>
        {rightIcon && <div style={{ maxWidth: '56px' }}>
          <IconButton disabled={isDisabled} onClick={onClick}>{rightIcon}</IconButton>
        </div>}
      </div>
    </div>
  );
}

export default TaskItem;
