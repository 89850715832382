import { useCallback, useEffect, useMemo, useState } from 'react';

import { WebGLRenderer, PCFShadowMap, Scene, PerspectiveCamera, Group, Clock, FogExp2, Mesh, MeshPhongMaterial, Vector3, Vector2, Raycaster } from 'three';
// @ts-ignore
import { OrbitControls } from 'three/addons/controls/OrbitControls.js';
// @ts-ignore
import { Tree } from '@dkostenevich/ez-tree';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { SMAAPass } from 'three/examples/jsm/postprocessing/SMAAPass.js';
import { OutputPass } from 'three/examples/jsm/postprocessing/OutputPass.js';
import { NeutralToneMapping } from 'three/src/constants.js';
import { calculateOverallGrowth, getRandomSeed, maxSeedIndex } from '../../core/tree';
import { useAppSelector } from '../../store/hooks';
import IconButton from '../../components/common/iconButton';
import { getTreePreset } from '../../core/tree/presets';

// import { Environment as HalloweenEnv } from './environment/halloween/environment';
import { Environment as LowEnv } from './environment/low/environment';
import { Environment as MediumEnv } from './environment/medium/environment';
import { Environment as HighEnv } from './environment/high/environment';

import { ReactComponent as ArrowsInSimple } from '../../assets/ArrowsInSimple.svg';
import { ReactComponent as SettingsIcon } from '../../assets/SettingsIcon.svg';
import DialogChangeScene from '../../components/tree/dialogChangeScene';
import { FindObjectTaskAssets, FpsRate, IFetchedRanks, IFindObjectTask, IFindObjectTaskSettings, IReferal, ITreeItem, SceneQuality, TreeType } from '../../types/types';
import { useLocation } from 'react-router-dom';
import { timeout } from '../../utils/utils';
import TreeLoader from '../../components/loaders/treeLoader';
import { TextGeometry, FontLoader } from 'three/examples/jsm/Addons.js';
import { Flowers } from './environment/grass';
import { handleErrors } from '../../core/helpers';
import DialogFindObject from '../../components/tree/dialogFindObject';
// import { Unstable_NumberInput as NumberInput } from '@mui/base/Unstable_NumberInput';

let findObjectTaskSettings = {
  Flower: {
    clickedAmount: 0,
    isCompleted: false,
  },
  Candy: {
    clickedAmount: 0,
    isCompleted: false,
  },
}

const flowerClicksAmount = 25;

interface IProps {
  type: SceneQuality
}

const renderSettings = {
  fps: FpsRate.Medium,
  request: 0,
}

const minDistance = 50;
const maxDistance = 100;
const maxFriendTreesAmount = 10;
const maxRankTreesAmount = 10;

const scene = new Scene();
const clock = new Clock();
const forest = new Group();
forest.name = 'Forest';

const raycaster = new Raycaster();
const mouse = new Vector2();

const pineArray = [
  {
    score: 0,
    seed: getRandomSeed(),
    type: TreeType.Pine,
  },
  {
    score: 100000,
    seed: getRandomSeed(),
    type: TreeType.Pine,
  },
  {
    score: 200000,
    seed: getRandomSeed(),
    type: TreeType.Pine,
  },
  {
    score: 300000,
    seed: getRandomSeed(),
    type: TreeType.Pine,
  },
  {
    score: 400000,
    seed: getRandomSeed(),
    type: TreeType.Pine,
  },
  {
    score: 500000,
    seed: getRandomSeed(),
    type: TreeType.Pine,
  },
  {
    score: 600000,
    seed: getRandomSeed(),
    type: TreeType.Pine,
  },
]

function getRandomPosition() {
  const r = minDistance + Math.random() * maxDistance;
  const theta = 2 * Math.PI * Math.random();
  return new Vector3(r * Math.cos(theta), 0, r * Math.sin(theta));
}

async function createTree(forest: Group, item: ITreeItem, maxGrowFactor: number, font: any) {
  const maxIterations = 100;
  let randomPosition: Vector3;
  let isValid = false;
  let iteration = 0
  let minDistance = 50;

  while (!isValid) {
    randomPosition = getRandomPosition();
    isValid = !forest.children.some(c => c.position.distanceTo(randomPosition) <= minDistance);
    iteration++;
    if (iteration >= maxIterations) {
      minDistance -= 5;
      iteration = 0;
    }
  }

  const t = new Tree();
  t.position.set(randomPosition!.x, randomPosition!.y, randomPosition!.z);
  forest.add(t);
  await t.loadFromJson(getTreePreset(item.type));
  t.options.seed = item.seed;
  calculateOverallGrowth(t, item.type, null, null, null, item.score, maxGrowFactor, true);
  t.castShadow = true;
  t.receiveShadow = true;
  if (item.nickname) {
    await addLabel(t, item.nickname, font);
  }
}

async function addLabel(tree: any, labelText: string, font: any) {
  const geometry = new TextGeometry(labelText, {
    font: font,
    size: 4,
    depth: 1,
    curveSegments: 4,
    bevelEnabled: false,
  }) as any;
  geometry.computeBoundingBox();
  const materials = [
    new MeshPhongMaterial({ color: 0xf5e251, flatShading: true }), // front
    new MeshPhongMaterial({ color: 0x000000 }) // side
  ];
  const textMesh = new Mesh(geometry, materials);
  const centerOffset = - 0.5 * (geometry.boundingBox.max.x - geometry.boundingBox.min.x);
  textMesh.position.set(centerOffset, 5, 10);
  textMesh.rotation.x = 0;
  textMesh.rotation.y = Math.PI * 2;
  tree.add(textMesh);
}

//todo: optimize
async function loadTrees(forest: Group, friends: IReferal[], ranks: IFetchedRanks, maxGrowFactor: number, userId: number, font: any) {
  const filteredRanks = ranks.items.filter((item) => {
    const id = item.id;
    if (Number(id) === userId) {
      return false;
    }
    for (let i = 0; i < friends.length; i++) {
      if (id === friends[i].id) {
        return false;
      }
    }
    return true;
  });

  const ranksArray = filteredRanks.map((item) => {
    const randomSeed = Number(item.id) % maxSeedIndex;
    return { score: item.value, nickname: item.username, seed: item.treeSettings?.seed || randomSeed, type: item.treeSettings?.type || TreeType.Ash } as ITreeItem;
  });
  const friendsArray = friends.map((item) => {
    const randomSeed = Number(item.id) % maxSeedIndex;
    return { score: item.score, nickname: item.username, seed: item.treeSettings?.seed || randomSeed, type: item.treeSettings?.type || TreeType.Ash } as ITreeItem;
  });
  const items = ranksArray.slice(0, maxRankTreesAmount).concat(friendsArray.slice(0, maxFriendTreesAmount).concat(pineArray));
  items.forEach((item) => {
    createTree(forest, item, maxGrowFactor, font);
  });
}

const TreeNew = ({ type }: IProps) => {
  const maxGrowFactor = useAppSelector(state => state.settings.settings.grow.maxGrowFactor);
  const configuration = useAppSelector(state => state.settings.configuration);
  const ranks = useAppSelector(state => state.statistics.ranks);
  const friends = useAppSelector(state => state.account.referals.data);
  const id = useAppSelector(state => state.account.account.id);
  const growAmount = useAppSelector(state => state.account.account.score);
  const [isCreated, setIsCreated] = useState(false);
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const userTasks = useAppSelector(state => state.account.userTasks);
  const tasks = useAppSelector(state => state.settings.settings.tasks);
  const [isFindObjectModalOpen, setFindObjectModalOpen] = useState(false);
  const [activeFindObjectTask, setActiveFindObjectTask] = useState<null | IFindObjectTask | undefined>(null);
  const location = useLocation();

  const handleClickFindObjectItem = useCallback(async (items: any[], asset: FindObjectTaskAssets, clickAmount: number) => {
    try {
      findObjectTaskSettings[asset].clickedAmount += 1;
      const found = items.find((item: any) => item.secret);
      items.forEach((element: any) => {
        element.removeFromParent();
      });
      if (findObjectTaskSettings[asset].isCompleted) {
        return;
      }
      if (found || findObjectTaskSettings[asset].clickedAmount > clickAmount) {
        const task = tasks.find((item) => item.type === 'FindObject' && (item.settings as IFindObjectTaskSettings).data.asset === asset);
        const userTask = userTasks.find((item) => item.taskId === task?.id);
        if (userTask?.operation === 'Claim') {
          setActiveFindObjectTask(task as IFindObjectTask);
          setFindObjectModalOpen(true);
        }
      }
    } catch (err: any) {
      handleErrors(err);
    }
  }, [tasks, userTasks]);

  const handleCloseFindObjectModal = useCallback(async (asset?: FindObjectTaskAssets) => {
    if (asset) {
      findObjectTaskSettings[asset].isCompleted = true;
    }
    setFindObjectModalOpen(false);
    setActiveFindObjectTask(null);
  }, []);

  // const [testGrowth, setTestGrowth] = useState<number>(0);

  const environment = useMemo(() => {
    let environment;
    switch (type) {
      case SceneQuality.Low:
        environment = new LowEnv();
        break;
      case SceneQuality.Medium:
        environment = new MediumEnv();
        break;
      case SceneQuality.High:
        environment = new HighEnv();
        break;
      default:
        environment = new LowEnv();
        break;
    }
    environment.skybox.sunAzimuth = 195;
    environment.skybox.sunSize = 2;
    environment.skybox.sunElevation = 20;
    return environment;
  }, [type]);

  const renderer = useMemo(() => {
    const renderer = new WebGLRenderer({ alpha: true, antialias: true });
    if (!isPageLoaded) {
      return null;
    }
    const appDiv = document.getElementById('tree');
    renderer.setClearColor(0);
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setPixelRatio(devicePixelRatio);
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = PCFShadowMap;
    renderer.toneMapping = NeutralToneMapping;
    renderer.toneMappingExposure = 2;
    appDiv?.appendChild(renderer.domElement);

    return renderer;
  }, [isPageLoaded]);

  const camera = useMemo(() => {
    return new PerspectiveCamera(60, window.innerWidth / window.innerHeight, 0.1, 2000) as any;
  }, []);

  const controls = useMemo(() => {
    if (!renderer) {
      return null;
    }
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.minPolarAngle = Math.PI / 2 - 0.4;
    controls.maxPolarAngle = Math.PI / 2;
    controls.enablePan = true;
    controls.minDistance = 10;
    controls.maxDistance = 300;
    if (type !== SceneQuality.Low) {
      controls.enableDamping = true;
    }
    return controls
  }, [camera, renderer, type]);

  const resetCameraPosition = useCallback(() => {
    controls?.reset();
  }, [controls]);

  const tree = useMemo(() => {
    // @ts-ignore
    const tree = new Tree();
    tree.castShadow = true;
    tree.receiveShadow = true;
    return tree;
  }, []);

  const smaaPass = useMemo(() => {
    if (!renderer) {
      return null;
    }
    const smaaPass = new SMAAPass(
      window.innerWidth * renderer.getPixelRatio(),
      window.innerHeight * renderer.getPixelRatio());
    return smaaPass;
  }, [renderer]);

  const composer = useMemo(() => {
    if (!renderer || !smaaPass) {
      return null;
    }
    const composer = new EffectComposer(renderer);
    composer.addPass(new RenderPass(scene, camera));
    composer.addPass(smaaPass);
    composer.addPass(new OutputPass());
    return composer;
  }, [camera, renderer, smaaPass]);

  const renderTree = useCallback(async () => {
    if (!renderer || !controls || !environment) {
      return;
    }
    const appDiv = document.getElementById('tree');
    function animate() {
      renderSettings.request = requestAnimationFrame(animate);
      if (renderSettings.fps === FpsRate.Lowest) {
        return;
      }
      const t = clock.getElapsedTime();
      tree.update(t);
      controls.update();
      scene?.getObjectByName('Forest')?.children.forEach((o: any) => (o as any)?.update(t));
      environment.update(t);
      composer?.render();
    }

    function resize() {
      if (!smaaPass || !composer || !renderer || !appDiv) {
        return;
      }
      renderer.setSize(appDiv.clientWidth, appDiv.clientHeight);
      smaaPass.setSize(appDiv.clientWidth, appDiv.clientHeight);
      composer.setSize(appDiv.clientWidth, appDiv.clientHeight);
      camera.aspect = appDiv.clientWidth / appDiv.clientHeight;
      camera.updateProjectionMatrix();
    }

    if (!isCreated) {
      scene.add(environment);
      scene.fog = new FogExp2(0x94b9f8, 0.0015);
      scene.add(forest);
      const { seed, type: treeType } = configuration;
      const loader = new FontLoader();
      const [font] = await Promise.all([
        await loader.loadAsync('/assets/fonts/roboto-regular.json'),
        tree.loadFromJson(getTreePreset(treeType)),
        timeout(2000)
      ]);

      scene.add(new Flowers({
        renderer,
        camera,
        raycaster,
        mouse,
        callback: (type: any, items: any) => {
          handleClickFindObjectItem(items, FindObjectTaskAssets.Flower, flowerClicksAmount);
        }
      }));

      tree.options.seed = Number(seed);
      scene.add(tree);
      calculateOverallGrowth(tree, treeType as TreeType, type, controls, camera, growAmount, maxGrowFactor, true);
      controls.saveState();
      loadTrees(forest, friends, ranks, maxGrowFactor, id, font);
      window.addEventListener('resize', resize);
      animate();
    }
    tree.generate();
    setIsCreated(true);
  }, [maxGrowFactor, growAmount, isCreated, camera, controls, renderer, tree, composer, type, friends, ranks, id, environment, smaaPass, configuration, handleClickFindObjectItem]);

  // const handleDemoGrowthChange = (event: any) => {
  //   const testGrowth = event.target.value;
  //   setTestGrowth(Number(testGrowth));
  // };

  // useEffect(() => {
  //   if (testGrowth) {
  //     calculateOverallGrowth(tree, type, controls, camera, testGrowth, 15, 600000);
  //   }
  // }, [testGrowth, tree, type, camera, controls, maxGrowFactor]);

  useEffect(() => {
    setIsPageLoaded(true);
    renderTree();
  }, [renderTree]);

  useEffect(() => {
    return () => {
      renderer?.dispose();
      composer?.dispose();
      cancelAnimationFrame(renderSettings.request as number);
    }
  }, [renderer, composer]);

  useEffect(() => {
    if (location.pathname !== '/') {
      renderSettings.fps = FpsRate.Lowest
    } else {
      renderSettings.fps = FpsRate.Medium;
    }
  }, [location]);

  if (!isCreated) {
    return <TreeLoader />
  }

  return <div style={{ position: 'absolute', top: '176px', right: '16px' }}>
    {isSettingsModalOpen && <DialogChangeScene type={type} handleClose={() => setIsSettingsModalOpen(false)} />}
    {isFindObjectModalOpen && activeFindObjectTask &&
      <DialogFindObject
        task={activeFindObjectTask}
        handleClose={handleCloseFindObjectModal}
      />}
    <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
      <IconButton onClick={resetCameraPosition}><ArrowsInSimple stroke='var(--basic--primary)' /></IconButton>
      <IconButton onClick={() => setIsSettingsModalOpen(true)}><SettingsIcon stroke='var(--basic--primary)' /></IconButton>
      {/* <NumberInput
        aria-label="Demo growth"
        min={0}
        max={600000}
        value={testGrowth || 0}
        step={1000}
        onChange={handleDemoGrowthChange}
        onInputChange={handleDemoGrowthChange}
      /> */}
    </div>
  </div>;
}

export default TreeNew;