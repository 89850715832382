import React from 'react';

import styles from './IconButton.module.scss';

interface IProps {
  children?: React.ReactNode,
  disabled?: boolean,
  size?: number,
  style?: object,
  onClick?: () => void,
}

const IconButton = ({ children, disabled, size, style, onClick }: IProps) => {
  return (
    <button onClick={onClick} className={`${styles.button} ${disabled ? styles.button__disabled : ''}`} style={size ? { width: size, height: size, ...style } : style}>
      {children}
    </button>
  );
}

export default IconButton;
