const tg = window.Telegram?.WebApp;

export interface IStorage {
  getItem(key: string): Promise<string>;
  setItem(key: string, data: string): Promise<void>;
}

class CloudStorage implements IStorage {
  public getItem(key: string): Promise<string> {
    return new Promise((res, rej) => {
      try {
        tg.CloudStorage.getItem(key, (error: string | null, value: string) => {
          if (error) {
            return rej(error);
          }
          res(value);
        });
      } catch (err) {
        rej(err);
      }
    });
  }

  public setItem(key: string, data: string): Promise<void> {
    return new Promise((res, rej) => {
      try {
        tg.CloudStorage.setItem(key, data, (error: string | null) => {
          if (error) {
            return rej(error);
          }
          res();
        });
      } catch (err) {
        rej(err);
      }
    });
  }

  public removeItem(key: string): Promise<void> {
    return new Promise((res, rej) => {
      try {
        tg.CloudStorage.removeItem(key, (error: string | null) => {
          if (error) {
            return rej(error);
          }
          res();
        });
      } catch (err) {
        rej(err);
      }
    });
  }
}

class WebStorage implements IStorage {
  public getItem(key: string): Promise<string> {
    return Promise.resolve(localStorage.getItem(key) as string);
  }

  public setItem(key: string, data: string): Promise<void> {
    localStorage.setItem(key, data);
    return Promise.resolve();
  }

  public removeItem(key: string): Promise<void> {
    localStorage.removeItem(key);
    return Promise.resolve();
  }
}

const storage = tg.initData ? new CloudStorage() : new WebStorage();

export default storage;