import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IAds, IApplicationSettings, IConfiguration, IGrow, INetworkSettings, IReplenish, ISocialSettings } from '../../types/types'
import { handleErrors } from '../../core/helpers'
import { getAuthSettings } from '../../api/auth'

export const fetchSettings = createAsyncThunk<IApplicationSettings, undefined, { rejectValue: string }>(
  'settings/fetchSettings',
  async (_, { rejectWithValue }) => {
    try {
      return await getAuthSettings();
    } catch (err: any) {
      handleErrors(err);
      return rejectWithValue(err.message);
    }
  }
)

interface IAssetNames {
  'Balance': string,
  'Score': string,
}

interface SettingsState {
  settings: IApplicationSettings,
  assetNames: IAssetNames,
  explorerUrl: string,
  configuration: IConfiguration,
}

const initialState: SettingsState = {
  settings: {
    ads: {} as IAds,
    wallet: { minimumReferrals: 0 },
    claimRewards: { repeat: 0 },
    grow: {} as IGrow,
    tasks: [],
    social: {} as ISocialSettings,
    replenish: { assets: [], repeat: 0 } as IReplenish,
    dailyRewardsSchedule: { days: [] },
    networkSettings: {} as INetworkSettings,
    assets: [],
  } as IApplicationSettings,
  assetNames: {} as IAssetNames,
  explorerUrl: '',
  configuration: {
    credentials: '',
    type: '',
    seed: '',
  },
}

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setConfiguration: (state, action: PayloadAction<IConfiguration>) => {
      state.configuration = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSettings.fulfilled, (state, action) => {
      const assetNames = action.payload.assets.reduce((a, c) => {
        a[c.type] = c.name;
        return a;
      }, {} as any);
      state.settings = action.payload;
      state.assetNames = assetNames;
      state.explorerUrl = action.payload.networkSettings.explorerUrl;
    })
  }
})

export default settingsSlice.reducer;
export const { setConfiguration } = settingsSlice.actions;