
import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { processTask } from '../../../api/auth';
import { handleErrors, openLink } from '../../../core/helpers';
import useFormattedAsset from '../../../hooks/useFormattedAsset';
import { IProcessTask, IWatchTaskItem } from '../../../types/types';
import DialogVerifyProtectedForm from '../dialogVerifyProtectedForm';
import TaskItem from '../taskItem';

import { ReactComponent as YoutubeIcon } from '../../../assets/tasks/YoutubeIcon.svg';
import { useTranslation } from 'react-i18next';
interface IProps {
  task: IWatchTaskItem,
  onClick: (task: IProcessTask) => void,
}

const TaskWatchItem = ({ task, onClick }: IProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const formattedAsset = useFormattedAsset(null, task.reward);
  const { t } = useTranslation();

  const handleStart = useCallback(async () => {
    try {
      const res = await processTask(task.id);
      if (res.status === 'RewardClaimed') {
        toast(t('toasts.defaultReplenishText', { replenishAmount: `${formattedAsset.amount} ${formattedAsset.name}` }));
      }
      onClick(res);
      openLink(task.settings.link);
    } catch (err: any) {
      handleErrors(err);
    }
  }, [onClick, task.id, task.settings.link, formattedAsset.amount, formattedAsset.name, t]);

  const handleVerify = useCallback(async () => {
    if (task.settings.protected) {
      return setIsOpen(true);
    }
    try {
      setIsLoading(true);
      const res = await processTask(task.id);
      if (res.status === 'RewardClaimed') {
        toast(t('toasts.defaultReplenishText', { replenishAmount: `${formattedAsset.amount} ${formattedAsset.name}` }));
      }
      onClick(res);
    } catch (err: any) {
      handleErrors(err);
      openLink(task.settings.link);
    } finally {
      setIsLoading(false);
    }
  }, [onClick, task.id, task.settings.protected, formattedAsset.amount, formattedAsset.name, task.settings.link, t]);

  const handleProtectedVerify = useCallback(async (password: string) => {
    try {
      setIsLoading(true);
      const payload = {
        Type: 'Watch',
        password
      };
      const res = await processTask(task.id, payload);
      if (res.status === 'RewardClaimed') {
        toast(t('toasts.defaultReplenishText', { replenishAmount: `${formattedAsset.amount} ${formattedAsset.name}` }));
      }
      onClick(res);
      setIsOpen(false);
    } catch (err: any) {
      handleErrors(err);
      openLink(task.settings.link);
    } finally {
      setIsLoading(false);
    }
  }, [onClick, task.id, formattedAsset.amount, formattedAsset.name, task.settings.link, t]);

  const handleClick = useCallback(() => {
    if (task.operation === 'Start') {
      return handleStart();
    }
    if (task.operation === 'Verify') {
      return handleVerify();
    }
  }, [handleStart, handleVerify, task.operation]);

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      {isOpen && <DialogVerifyProtectedForm handleClose={() => setIsOpen(false)} onClick={handleProtectedVerify} />}
      <TaskItem task={task} isLoading={isLoading} status={task.status} leftIcon={<YoutubeIcon />} description={`+ ${formattedAsset.amount} ${formattedAsset.name}`} onClick={handleClick} />
    </div>
  );
}

export default TaskWatchItem;
