import { useMemo, useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { sendTransaction } from '../../../api/wallet';
import { getWallet, isCredentialsEncrypted } from '../../../core/wallet';
import { useAppSelector } from '../../../store/hooks';
import { handleErrors } from '../../../core/helpers';
import Dialog from '../../common/dialog';

import styles from './DialogTransactionForm.module.scss';
import CustomInput from '../../common/customInput';

import { ReactComponent as QRIcon } from '../../../assets/common/QRIcon.svg';
import { ReactComponent as EyeOpenIcon } from '../../../assets/common/EyeOpenIcon.svg';
import { ReactComponent as EyeClosedIcon } from '../../../assets/common/EyeClosedIcon.svg';
import Button from '../../common/button';
import ButtonLoader from '../../common/loaders/buttonLoader';
import { decrypt } from '../../../core/encryptor';
import { NumericFormat } from 'react-number-format';
import { useTranslation } from 'react-i18next';

const tg = window.Telegram.WebApp;

interface IProps {
  handleClose: () => void,
  handleTransactionFormSend: () => void,
  balance: number,
}

const DialogTransactionForm = ({ handleClose, balance, handleTransactionFormSend }: IProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [amount, setAmount] = useState<string>('');
  const [addressTo, setAddressTo] = useState<string>('');
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<string>('');
  const settings = useAppSelector(store => store.settings.settings);
  const encryptedMnemoic = useAppSelector(state => state.account.credentials);
  const nomalizedAmount = useMemo(() => Number(amount || 0) * Math.pow(10, settings.networkSettings.decimals),
    [amount, settings.networkSettings.decimals]);

  const { t } = useTranslation();

  const isEncrypted = useMemo(() => {
    return isCredentialsEncrypted(encryptedMnemoic);
  }, [encryptedMnemoic]);

  const onClick = useCallback(async () => {
    setIsDirty(true);
    if (!addressTo || !amount || balance < nomalizedAmount) {
      return;
    }
    if (isEncrypted && !password) {
      return;
    }
    try {
      setIsLoading(true);
      let mnemonic = encryptedMnemoic;
      if (isEncrypted) {
        mnemonic = await decrypt(encryptedMnemoic, password);
        if (!mnemonic) {
          return setError(t('pages.wallet.popups.fields.password.errors.invalidPassword'));
        }
      }
      const wallet = getWallet(mnemonic);
      const transaction = wallet.createTransaction(nomalizedAmount,
        addressTo,
        settings.networkSettings.networkVersion,
        settings.networkSettings.minTransactionFee);
      wallet.signTransaction(transaction);
      await sendTransaction(transaction);
      handleTransactionFormSend();
      toast.success('Transaction sent!');
      handleClose();
    } catch (err: any) {
      handleErrors(err);
    } finally {
      setIsLoading(false);
    }
  }, [
    addressTo,
    amount,
    settings.networkSettings.networkVersion,
    handleClose,
    balance,
    handleTransactionFormSend,
    settings.networkSettings.minTransactionFee,
    password,
    nomalizedAmount,
    encryptedMnemoic,
    isEncrypted,
    t
  ]);

  const amountErrorText = useMemo(() => {
    if (isDirty && !amount) {
      return t('defaultErrorText');
    }
    if (isDirty && balance < nomalizedAmount) {
      return t('pages.wallet.popups.transaction.fields.amount.errors.notEnoughTokens');
    }
    return null;
  }, [amount, isDirty, balance, nomalizedAmount, t]);

  const addressErrorText = useMemo(() => {
    if (isDirty && !addressTo) {
      return t('defaultErrorText');
    }
    return null;
  }, [addressTo, isDirty, t]);

  const passwordErrorText = useMemo(() => {
    if (error) {
      return error;
    }
    if (isDirty && !password) {
      return t('defaultErrorText');
    }
    return null;
  }, [isDirty, password, error, t]);

  const onPasswordChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    setError('');
  }, []);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const currentIcon = useMemo(() => {
    if (showPassword) {
      return <EyeClosedIcon onClick={handleClickShowPassword} />
    }
    return <EyeOpenIcon onClick={handleClickShowPassword} />
  }, [showPassword]);

  const handleScanIconClick = useCallback(() => {
    tg.showScanQrPopup({}, (address: string) => {
      setAddressTo(address);
      tg.closeScanQrPopup();
    });
  }, []);

  const handleKeyDown = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onClick();
    }
  }, [onClick]);

  return (
    <Dialog
      onClose={handleClose}
      title={t('pages.wallet.popups.transaction.title')}
    >
      <div className={styles.dialog__content}>
        <NumericFormat
          customInput={CustomInput}
          decimalScale={2}
          decimalSeparator="."
          fixedDecimalScale
          onValueChange={(number: { value: string, formattedValue: string }) => setAmount(number.value)}
          thousandSeparator=","
          value={amount}
          error={!!amountErrorText}
          helperText={amountErrorText}
          title={t('pages.wallet.popups.transaction.fields.amount.title')}
        />
        <CustomInput
          title={t('pages.wallet.popups.transaction.fields.addressTo.title')}
          type='text'
          value={addressTo}
          onChange={(e) => setAddressTo(e.target.value)}
          helperText={addressErrorText}
          error={!!addressErrorText}
          icon={<QRIcon onClick={handleScanIconClick} />}
          onKeyDown={handleKeyDown}
        />
        {isEncrypted && <CustomInput
          title={t('pages.wallet.popups.transaction.fields.password.title')}
          type={showPassword ? 'text' : 'password'}
          error={!!passwordErrorText}
          helperText={passwordErrorText}
          value={password}
          onChange={onPasswordChange}
          onKeyDown={handleKeyDown}
          icon={currentIcon}
        />}
        <Button
          onClick={onClick}
          disabled={isLoading}
          endIcon={isLoading ? <ButtonLoader /> : null}
        >
          {t('pages.wallet.popups.transaction.buttons.send.title')}
        </Button>
      </div>
    </Dialog>
  );
}

export default DialogTransactionForm;
