import { useCallback, useEffect, useMemo, useState } from 'react';

import { WebGLRenderer, PCFSoftShadowMap, Scene, Fog, AmbientLight, DirectionalLight, PerspectiveCamera } from 'three';
// @ts-ignore
import { OrbitControls } from 'three/addons/controls/OrbitControls.js';
// @ts-ignore
import { Tree } from '@dkostenevich/ez-tree';
import { NeutralToneMapping } from 'three/src/constants.js';
import { calculateOverallGrowth } from '../../core/tree';
import { useAppSelector } from '../../store/hooks';
import IconButton from '../../components/common/iconButton';

import { getTreePreset } from '../../core/tree/presets';
import { ReactComponent as ArrowsInSimple } from '../../assets/ArrowsInSimple.svg';
import { ReactComponent as SettingsIcon } from '../../assets/SettingsIcon.svg';
import DialogChangeScene from '../../components/tree/dialogChangeScene';
import { FpsRate, SceneQuality, TreeType } from '../../types/types';
import TreeLoader from '../../components/loaders/treeLoader';
import { timeout } from '../../utils/utils';
import { useLocation } from 'react-router-dom';

interface IProps {
  type: SceneQuality
}

const renderSettings = {
  delta: 0,
  fps: FpsRate.Medium,
  request: 0,
}

const scene = new Scene();
scene.fog = new Fog(0x9dccff, 150, 200);

const TreeOld = ({ type }: IProps) => {
  const maxGrowFactor = useAppSelector(state => state.settings.settings.grow.maxGrowFactor);
  const configuration = useAppSelector(state => state.settings.configuration);
  const growAmount = useAppSelector(state => state.account.account.score);
  const [isCreated, setIsCreated] = useState(false);
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const renderer = useMemo(() => {
    const renderer = new WebGLRenderer({ alpha: true, antialias: true });
    if (!isPageLoaded) {
      return renderer;
    }
    renderer.setClearColor(0);
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = PCFSoftShadowMap;
    renderer.toneMapping = NeutralToneMapping;
    const appDiv = document.getElementById('tree');
    appDiv?.appendChild(renderer.domElement);
    return renderer;
  }, [isPageLoaded]);

  const camera = useMemo(() => {
    return new PerspectiveCamera(60, window.innerWidth / window.innerHeight, 0.1, 1000) as any;
  }, []);

  const tree = useMemo(() => {
    // @ts-ignore
    const tree = new Tree();
    tree.castShadow = true;
    tree.receiveShadow = true;
    return tree;
  }, []);

  const controls = useMemo(() => {
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.minDistance = 10;
    controls.maxDistance = 150;
    controls.autoRotate = true;
    controls.autoRotateSpeed = 1;
    controls.enablePan = false;
    return controls
  }, [camera, renderer.domElement]);

  const resetCameraPosition = useCallback(() => {
    controls?.reset();
  }, [controls]);

  const renderTree = useCallback(async () => {
    const appDiv = document.getElementById('tree');
    function animate() {
      renderSettings.request = requestAnimationFrame(animate);
      if (renderSettings.fps === FpsRate.Lowest) {
        return;
      }
      controls.update();
      renderer.render(scene, camera);
    }

    function resize() {
      if (!appDiv) {
        return;
      }
      renderer.setSize(appDiv.clientWidth, appDiv.clientHeight);
      camera.aspect = appDiv.clientWidth / appDiv.clientHeight;
      camera.updateProjectionMatrix();
    }

    if (!isCreated) {
      const ambientLight = new AmbientLight(0xffffff, 0.5);
      scene.add(ambientLight);
      const sunlight = new DirectionalLight();
      sunlight.intensity = 5;
      sunlight.position.set(50, 50, 50);
      sunlight.castShadow = true;
      scene.add(sunlight);

      const { seed, type: treeType } = configuration;
      await Promise.all([tree.loadFromJson(getTreePreset(treeType)), timeout(2000)]);

      tree.options.seed = Number(seed);
      tree.castShadow = true;
      tree.receiveShadow = true;
      scene.add(tree);
      calculateOverallGrowth(tree, treeType as TreeType, type, controls, camera, growAmount, maxGrowFactor, true);
      controls.saveState();
      window.addEventListener('resize', resize);
      animate();
    }

    tree.generate();
    setIsCreated(true);
  }, [maxGrowFactor, growAmount, isCreated, camera, controls, renderer, tree, type, configuration]);

  useEffect(() => {
    return () => {
      renderer?.dispose();
      cancelAnimationFrame(renderSettings.request as number);
    }
  }, [renderer]);

  useEffect(() => {
    setIsPageLoaded(true);
    renderTree();
  }, [renderTree, growAmount]);

  useEffect(() => {
    if (location.pathname !== '/') {
      renderSettings.fps = FpsRate.Lowest
    } else {
      renderSettings.fps = FpsRate.Medium;
    }
  }, [location]);

  if (!isCreated) {
    return <TreeLoader />
  }

  return <div style={{ position: 'absolute', top: '176px', right: '16px' }}>
    {isOpen && <DialogChangeScene type={type} handleClose={() => setIsOpen(false)} />}
    <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
      <IconButton onClick={resetCameraPosition}><ArrowsInSimple /></IconButton>
      <IconButton onClick={() => setIsOpen(true)}><SettingsIcon /></IconButton>
    </div>
  </div>;
}

export default TreeOld;