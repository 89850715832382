import { QRCodeSVG } from 'qrcode.react';
import { useTranslation } from 'react-i18next';
import Dialog from '../../common/dialog';

import styles from './DialogShareQr.module.scss';

interface IProps {
  handleClose: () => void,
  address: string,
}

const DialogShareQr = ({ address, handleClose }: IProps) => {
  const { t } = useTranslation();
  return (
    <Dialog
      onClose={handleClose}
      title={t('pages.wallet.popups.share.title')}
    >
      <div className={styles.content__wrapper}>
        <div className={styles.qr__wrapper}>
          <QRCodeSVG className='importantSvg' value={address} size={256} style={{ width: '100%' }} />
        </div>
      </div>
    </Dialog>
  );
}

export default DialogShareQr;
